import React from 'react';
import PropTypes from 'prop-types';
import CccisdToggle from 'cccisd-toggle';
import Notification from 'cccisd-notification';
import Loader from 'cccisd-loader';
import Axios from 'cccisd-axios';
import { client as apollo, gql } from 'cccisd-apollo';
import { Report, PrintOnly, BrowserOnly, Page } from 'cccisd-laravel-report';
import { addEvent } from 'cccisd-laravel-eventlog';
import Tooltip from 'cccisd-tooltip';
import Tabs from 'cccisd-tabs';
import Triangle from 'cccisd-icons/triangle2';
import Square from 'cccisd-icons/square';
import Diamond from 'cccisd-icons/diamond4';
import Circle from 'cccisd-icons/circle2';
import Check from 'cccisd-icons/checkmark-circle';
import CheckMark from 'cccisd-icons/checkmark';
import Info from 'cccisd-icons/info2';

import ArrowSVG from './NeedsAttention_Strength-Arrow.svg';
import style from './style.css';
import Notes from '../CheckUpFeedbackCoach/Notes';
import RowDrop from '../CheckUpFeedbackCoach/RowDrop';
import StartSurvey from '../CheckUpFeedbackCoach/StartSurvey';
import EditSurvey from '../CheckUpFeedbackCoach/EditSurvey';
import Goals from '../CheckUpFeedbackCoach/Goals';
import InterventionPlanning from '../CheckUpFeedbackCoach/InterventionPlanning';
import ActivityLog from './ActivityLog';
import ViewLinks from 'js/components/ViewLinks';

import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import _last from 'lodash/last';
import add from 'date-fns/add_days';
import isAfter from 'date-fns/is_after';
import format from 'date-fns/format';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

export default class CheckUpFeedbackTeacher extends React.Component {
    static propTypes = {
        content: PropTypes.object.isRequired,
        match: PropTypes.object,
    };

    state = {
        loading: true,
        firstClick: null,
        secondClick: null,
        shareReport: false,
        currentPawn: null, // maybe remove
        goals: {},
        devTags: {},
        notes: {},
        goalChanges: 0,
    };

    // LIFE CYCLE
    componentDidMount = async () => {
        window.addEventListener('beforeunload', this.handleUnload);

        const user = await this.getUserData();

        if (user) {
            const hashArr = user.name.split('--');
            const metrics = await this.getMetricsData(user);

            const messages = await Axios.get(Boilerplate.route('ccu.get.data'));
            let formattedMessages;
            if (messages.data.success) {
                formattedMessages = this.getMessages(messages);
            }
            const stateKeys = this.processQueryData(metrics, user);

            this.setState({
                loading: false,
                user,
                username: user.label,
                currentPawn: {
                    pawnId: user.value,
                    pawnHash: hashArr[0],
                    respondentHash: hashArr[1],
                },

                ...stateKeys,
                dropDownContent: formattedMessages,
            });
        }
    };

    getUserData = async () => {
        const {
            content: {
                checkUpTable: { userQuery },
            },
        } = this.props;

        const res = await apollo.query({
            query: gql`
                ${userQuery}
            `,
            fetchPolicy: 'network-only',
            variables: {
                pawnId: Fortress.user.acting.id,
                assignmentHandle: 'feedbackSurveyTeacher',
            },
        });
        if (res) {
            const user = this.getUser(res.data);
            return user;
        }
    };

    getMetricsData = async user => {
        const {
            content: {
                checkUpTable: { metricsQuery },
            },
        } = this.props;
        const metrics = await apollo.query({
            query: gql`
                ${metricsQuery}
            `,
            fetchPolicy: 'network-only',
            variables: {
                pawnId: user.value,
                deploymentId: user.deploymentId ? user.deploymentId : null,
                assignmentId: user.assignmentId ? user.assignmentId : null,
            },
        });

        return metrics;
    };
    // Process Dat

    processQueryData = (metrics, user) => {
        const assignmentProgress = metrics.data.roles.anyRole.assignmentProgress;
        const devTags = assignmentProgress.devTags;
        const timestamps = assignmentProgress.variableTimestamps;
        const reportShared = devTags.ShareReport === '1' ? true : false;
        const notes = assignmentProgress.notes;
        const notesMetrics = metrics.data.metrics.variableList;
        const goalsMetrics = assignmentProgress.goals;
        const initialGoals = this.processGoals(goalsMetrics);
        const propGoals = this.getGoalsFromProps();
        let completedDate;
        completedDate = assignmentProgress.completedDate
            ? format(assignmentProgress.completedDate, 'MM/DD/YYYY')
            : '';
        const devTagArr = Object.keys(devTags).map(item => {
            return {
                value: devTags[item],
                key: item,
            };
        });
        const noDevTags = devTagArr
            .filter(item => item.value !== 'MetricsDevTags')
            .every(item => item.value === null || item.value === '');

        if (user.completedDate && !assignmentProgress.completedDate) {
            completedDate = user.completedDate;
        } else {
            completedDate = assignmentProgress.completedDate
                ? format(assignmentProgress.completedDate, 'MM/DD/YYYY')
                : '';
        }

        const dataObj = {
            devTags,
            timestamps,
            shareReport: reportShared,
            notes,
            notesMetrics,
            initialGoals: initialGoals ? initialGoals : propGoals,
            goals: initialGoals ? initialGoals : propGoals,
            completedDate,
            noDevTags,
        };
        return dataObj;
    };

    processGoals = metrics => {
        const newMetrics = _omit(metrics, '__typename');
        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        rows.forEach(item => {
            if (metrics[item.goalTag] === '1') {
                newMetrics[item.goalTag] = true;
            } else {
                newMetrics[item.goalTag] = false;
            }
        });

        return newMetrics;
    };

    processCheckUpData = (rows, state) => {
        const { devTags, goals, notes, dropDownContent } = state;

        const tableData = rows.map((r, i) => {
            const goalTrue = goals[r.goalTag];

            const check = (
                <span data-tip="Test" data-event="click focus">
                    <Check />
                </span>
            );

            const toggle = (
                <React.Fragment key={i}>
                    <BrowserOnly>
                        <div className={style.toggleWrapper}>
                            <CccisdToggle
                                name={r.label}
                                value={goals[r.goalTag]}
                                onChange={() => this.handleGoalChange(r)}
                            />
                        </div>
                    </BrowserOnly>
                    <PrintOnly>
                        {goalTrue && (
                            <div
                                style={{
                                    fontSize: '16pt',
                                    textAlign: 'center',
                                    color: '#458DCB',
                                    width: '100%',
                                }}
                            >
                                <CheckMark />
                            </div>
                        )}
                    </PrintOnly>
                </React.Fragment>
            );

            const noteContent = notes[r.notesTag];

            const note = (
                <React.Fragment key={r.label}>
                    <BrowserOnly>
                        <span className={style.notesSpan}>
                            <Notes
                                index={i}
                                label={r.label}
                                onSubmit={this.handleNotesSubmit}
                                devTag={r.notesTag}
                                initialValue={noteContent ? noteContent : ''}
                            />
                        </span>
                    </BrowserOnly>
                    <PrintOnly>
                        {noteContent && (
                            <div
                                style={{
                                    fontSize: '16pt',
                                    textAlign: 'center',
                                    color: '#458DCB',
                                    width: '100%',
                                }}
                            >
                                <CheckMark />
                            </div>
                        )}
                    </PrintOnly>
                </React.Fragment>
            );

            let finalRow;
            let newRow;
            if (r.isOther) {
                const labelTag = r.labelTag;
                const displayVal = devTags[labelTag] ? devTags[labelTag] : '';
                const label = (
                    <span>
                        Other:
                        <span className={style.otherLabel}>
                            {' '}
                            {displayVal}
                            {'  '}
                        </span>
                    </span>
                );
                newRow = [toggle, note, label];
            } else {
                newRow = [toggle, note, r.label];
            }

            let devTagArr = Object.keys(devTags).map(key => {
                return { [key]: devTags[key] };
            });

            const match = devTagArr.find(item => r.devTag in item);
            const score = match[r.devTag] !== null ? Math.floor(+match[r.devTag]) : null;

            let messageObj;
            if (dropDownContent) {
                messageObj = dropDownContent.find(item => item.handle === r.messageHandle);
            }

            const messages = messageObj ? messageObj.messages : r.messages;

            if (match) {
                switch (score) {
                    case 0:
                        finalRow = newRow.concat([check, '', '', '', '', '', messages]);
                        break;
                    case 1:
                        finalRow = newRow.concat(['', check, '', '', '', '', messages]);
                        break;
                    case 2:
                        finalRow = newRow.concat(['', '', check, '', '', '', messages]);
                        break;
                    case 3:
                        finalRow = newRow.concat(['', '', '', check, '', '', messages]);
                        break;
                    case 4:
                        finalRow = newRow.concat(['', '', '', '', check, '', messages]);
                        break;
                    case 5:
                        finalRow = newRow.concat(['', '', '', '', '', check, messages]);
                        break;
                    case null:
                        finalRow = newRow.concat(['', '', '', '', '', '', messages]);
                        break;
                    default:
                        break;
                }
            } else {
                finalRow = newRow.concat(['', '', '', '', '', '', messages]);
            }

            return finalRow;
        });
        return tableData;
    };

    //  Do something

    assignCell = cell => {
        cell.row = +cell.row;
        cell.col = +cell.col;

        if (this.state.row === cell.row && this.state.col === cell.col) {
            this.setState({
                row: null,
                col: null,
            });
        } else {
            this.setState({
                row: cell.row,
                col: cell.col,
            });
        }
    };

    determineSection = row => {
        let section;
        if (+row <= 4) {
            section = 'Classroom Structure';
        } else if (+row >= 5 && +row <= 10) {
            section = 'Instruction Management';
        } else if (+row >= 11 && +row <= 17) {
            section = 'Behavior Management';
        } else if (+row >= 18) {
            section = 'Classroom Climate';
        }

        return section;
    };

    downloadPdf = () => {
        this.setState({
            row: null,
            col: null,
        });

        setTimeout(() => {
            this.report.download();
            Notification({
                message: 'Please wait a moment while your PDF is prepared',
                type: 'info',
                duration: 5000,
            });
        }, 1000);
    };
    // get something

    getSection = row => {
        let section;
        row = +row;
        if (row <= 4) {
            section = 'Classroom Structure';
        } else if (row >= 5 && row <= 10) {
            section = 'Instruction Management';
        } else if (row >= 11 && row <= 17) {
            section = 'Behavior Management';
        } else if (row >= 18) {
            section = 'Classroom Climate';
        }

        return section;
    };

    getMessages = msg => {
        const data = msg.data.data;
        const classroomStructureData = data.classroomStructure
            ? JSON.parse(data.classroomStructure.data)
            : {};
        const instructionManagementData = data.instructionManagement
            ? JSON.parse(data.instructionManagement.data)
            : {};
        const behaviorManagementData = data.behaviorManagement
            ? JSON.parse(data.behaviorManagement.data)
            : {};
        const classroomClimateData = data.classroomClimate
            ? JSON.parse(data.classroomClimate.data)
            : {};

        const classroomStructureArr = this.handleOther(
            classroomStructureData,
            'classroomStructure'
        );
        const instructionManagementArr = this.handleOther(
            instructionManagementData,
            'instructionManagement'
        );
        const behaviorManagementArr = this.handleOther(
            behaviorManagementData,
            'behaviorManagement'
        );
        const classroomClimateArr = this.handleOther(classroomClimateData, 'classroomClimate');

        const combinedArr = classroomStructureArr.concat(
            instructionManagementArr,
            behaviorManagementArr,
            classroomClimateArr
        );

        const finalArr = combinedArr.map(item => {
            const key = Object.keys(item)[0];
            const messages = item[key].map((array, i) => {
                return {
                    column: i + 1,
                    bullets: array,
                };
            });

            return {
                handle: key,
                messages,
            };
        });

        return finalArr;
    };

    getGoalMetrics = goals => {
        let newGoals = {};
        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        rows.forEach(item => {
            newGoals[item.goalMetric] = goals[item.goalTag];
        });
        return newGoals;
    };

    getNotesFromProps = () => {
        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        const notes = rows.map(item => {
            return {
                label: item.label,
                notesTag: item.notesTag,
            };
        });
        return notes;
    };

    getGoalsFromProps = () => {
        let goals = {};
        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        for (let i = 0; i < rows.length; i++) {
            goals[rows[i].goalTag] = false;
        }

        return goals;
    };

    getDate = date => {
        const dateTimeArr = date.split(' ');
        const dateArr = dateTimeArr[0].split('-');
        const startedDate = new Date(dateArr[0], +dateArr[1] - 1, dateArr[2]);

        return format(startedDate, 'MM/DD/YYYY');
    };

    getUser = data => {
        const {
            roles: { teacher },
            events: { eventList },
        } = data;

        if (teacher) {
            const teacherId = +teacher.pawn.pawnId;
            const flowProgressList = teacher.teacherFlowProgress;
            const assignmentProgressList = teacher.assignmentProgressList;
            const parentPawns = teacher.parentRoles.anyRoleList;
            const pendingRequests = teacher.pendingRequestToMeList;
            const flowStarted = flowProgressList.find(
                thing => thing.completed === true && thing.flowHandle === 'survey6'
            );

            const flowCompleted = flowProgressList.find(
                thing =>
                    thing.completed === true &&
                    thing.flowHandle === 'survey11' &&
                    thing.responseSet === flowStarted.responseSet
            );

            const flowCompletedList = flowProgressList.filter(
                thing => thing.completed === true && thing.flowHandle === 'survey11'
            );

            const assignmentCompletedList = flowCompletedList.map(item => {
                const match = assignmentProgressList.find(
                    thing => thing.deployment.deploymentId === item.responseSet
                );
                return match;
            });

            let teacherProgress;

            if (flowStarted && !flowCompleted) {
                teacherProgress = assignmentProgressList.find(
                    thing => thing.deployment.deploymentId === flowStarted.responseSet
                );
            }

            const teacherCompletedList = assignmentCompletedList.filter(
                thing => thing !== undefined
            );

            const firstCompleted = teacherCompletedList.find(
                item =>
                    item.devTags.ShareReport === '1' ||
                    +item.devTags.SubmittedBy === teacher.pawn.pawnId
            );

            const currentCoach = teacher.parentRoles.coach.pawn.pawnId;

            let expirationDateTeacher;
            let deploymentIdTeacher;
            let assignmentIdTeacher;
            let assignmentProgressIdTeacher;
            let teacherIsNew;
            let completedDateTeacher;
            let completedSubmittedBy;
            let isExpired = false;
            let progressSubmittedBy;

            if (teacherProgress) {
                if (firstCompleted) {
                    const starterId = +teacherProgress.devTags.SubmittedBy;
                    completedDateTeacher = this.getDate(firstCompleted.completedAt);
                    deploymentIdTeacher = firstCompleted.deployment.deploymentId;
                    assignmentIdTeacher = firstCompleted.deployment.assignment.assignmentId;
                    assignmentProgressIdTeacher = firstCompleted.assignmentProgressId;
                    assignmentProgressIdTeacher = firstCompleted.assignmentProgressId;
                    completedSubmittedBy = firstCompleted.devTags.SubmittedBy;

                    if (+starterId === +teacherId || +currentCoach === +starterId) {
                        // const completedStartedDate = this.getDate(teacherProgress.startedAt);
                        const progressStartedDate = this.getDate(flowStarted.startedAt);

                        expirationDateTeacher = add(progressStartedDate, 30);

                        progressSubmittedBy = teacherProgress.devTags.SubmittedBy;
                    } else {
                        isExpired = true;
                    }
                } else {
                    // None Completed
                    const deploymentId = teacherProgress.deployment.deploymentId;
                    const startedDate = this.getDate(flowStarted.startedAt);

                    const starterId = +teacherProgress.devTags.SubmittedBy;

                    if (+starterId === +teacherId || +currentCoach === +starterId) {
                        expirationDateTeacher = add(startedDate, 30);
                        deploymentIdTeacher = deploymentId;
                        assignmentIdTeacher = teacherProgress.deployment.assignment.assignmentId;
                        assignmentProgressIdTeacher = teacherProgress.assignmentProgressId;
                        completedSubmittedBy = starterId;
                        progressSubmittedBy = starterId;
                    } else {
                        // started by coach
                        // check current coach
                        // coach has chagned between now and last survey

                        expirationDateTeacher = add(startedDate, 30);
                        isExpired = true;
                        completedSubmittedBy = null;
                        progressSubmittedBy = null;
                    }
                }
            } else if (!teacherProgress) {
                // no progress
                if (teacherCompletedList.length > 0) {
                    // first completed that is shared
                    if (firstCompleted) {
                        // check if coach chagned
                        const startedDate = this.getDate(firstCompleted.startedAt);

                        const starterId = +firstCompleted.devTags.SubmittedBy;

                        if (+starterId === +teacherId || +currentCoach === +starterId) {
                            completedDateTeacher = this.getDate(firstCompleted.completedAt);

                            expirationDateTeacher = add(startedDate, 30);
                            deploymentIdTeacher = firstCompleted.deployment.deploymentId;
                            assignmentIdTeacher = firstCompleted.deployment.assignment.assignmentId;
                            assignmentProgressIdTeacher = firstCompleted.assignmentProgressId;
                            completedSubmittedBy = starterId;
                        } else {
                            completedDateTeacher = this.getDate(firstCompleted.completedAt);

                            expirationDateTeacher = startedDate;
                            deploymentIdTeacher = firstCompleted.deployment.deploymentId;
                            assignmentIdTeacher = firstCompleted.deployment.assignment.assignmentId;
                            assignmentProgressIdTeacher = firstCompleted.assignmentProgressId;
                            completedSubmittedBy = starterId;
                            isExpired = true;
                        }
                    } else {
                        const firstUnshared = teacherCompletedList.find(
                            item => item.devTags.ShareReport === null
                        );
                        if (firstUnshared) {
                            const startedDate = this.getDate(firstUnshared.startedAt);
                            const starterId = +firstUnshared.devTags.SubmittedBy;
                            if (+starterId === +teacherId || +currentCoach === +starterId) {
                                completedDateTeacher = this.getDate(firstUnshared.completedAt);
                                expirationDateTeacher = add(startedDate, 30);
                                completedSubmittedBy = starterId;
                            } else {
                                completedDateTeacher = this.getDate(firstUnshared.completedAt);
                                expirationDateTeacher = add(startedDate, 30);
                                isExpired = true;
                                completedSubmittedBy = starterId;
                            }
                        }
                    }
                } else {
                    teacherIsNew = true;
                }
            }

            const coach = teacher.parentRoles.coach;

            return {
                value: +teacher.pawn.pawnId,
                name: teacher.pawn.pawnHash + '--' + teacher.pawn.respondentHash,
                label: teacher.user.fullName,
                role: teacher.pawn.role,
                pawn: { pawnHash: teacher.pawn.pawnHash },
                surveyInProgress: teacherProgress,
                progressSubmittedBy: +progressSubmittedBy === +teacherId ? 'teacher' : 'coach',
                isNew: teacherIsNew ? teacherIsNew : false,
                expirationDate: format(expirationDateTeacher, 'MM/DD/YYYY'),
                isExpired: isExpired ? true : isAfter(new Date(), expirationDateTeacher),
                deploymentId: deploymentIdTeacher,
                assignmentId: assignmentIdTeacher,
                assignmentProgressId: assignmentProgressIdTeacher,
                completedDate: completedDateTeacher,
                completedSubmittedBy: +completedSubmittedBy === +teacherId ? 'teacher' : 'coach',
                coach: coach.pawn.pawnId
                    ? {
                          name: coach.user.fullName,
                          pawnId: coach.pawn.pawnId,
                      }
                    : null,
                pendingInvites: pendingRequests,
                parentPawns,
                userData: {
                    pawnHash: teacher.pawn.pawnHash,
                    firstName: teacher.user.firstName,
                    lastName: teacher.user.lastName,
                    username: teacher.user.username,
                },
                eventList,
            };
        }
    };

    getCell = (cell, row, col, sectionIndex) => {
        const newStyle = this.getStyle(row, col, sectionIndex);

        if (+col < 3) {
            return (
                <td className={`row${row} col${col} ${newStyle}`} key={`row${row}col${col}`}>
                    {cell}
                </td>
            );
        }
        return (
            <td
                className={`row${row} col${col} ${newStyle} ${style.colored}`}
                key={`row${row}col${col}`}
                onClick={this.handleCellClick}
            >
                {cell}
            </td>
        );
    };

    getStyle = (row, col, sectionIndex) => {
        let finalStyle =
            sectionIndex === 0 ? this.getSectionDivide(col) : this.getDefaultStyle(col);
        if (col === this.state.col && row === this.state.row) {
            finalStyle = this.getClickedStyle(col);
        }
        return finalStyle;
    };

    getSectionDivide = col => {
        let newStyle;
        switch (col) {
            case 0:
                newStyle = style.firstColDivide;
                break;
            case 1:
                newStyle = style.secondColDivide;
                break;
            case 2:
                newStyle = style.thirdColDivide;
                break;
            case 3:
                newStyle = style.fourthColDivide;
                break;
            case 4:
                newStyle = style.fifthColDivide;
                break;
            case 5:
                newStyle = style.sixthColDivide;
                break;
            case 6:
                newStyle = style.seventhColDivide;
                break;
            case 7:
                newStyle = style.eighthColDivide;
                break;
            case 8:
                newStyle = style.ninthColDivide;
                break;
            default:
                break;
        }
        return newStyle;
    };

    getClickedStyle = col => {
        let newStyle;
        switch (col) {
            case 0:
                newStyle = style.firstCol;
                break;
            case 1:
                newStyle = style.secondCol;
                break;
            case 2:
                newStyle = style.thirdCol;
                break;
            case 3:
                newStyle = style.fourthColOpen;
                break;
            case 4:
                newStyle = style.fifthColOpen;
                break;
            case 5:
                newStyle = style.sixthColOpen;
                break;
            case 6:
                newStyle = style.seventhColOpen;
                break;
            case 7:
                newStyle = style.eighthColOpen;
                break;
            case 8:
                newStyle = style.ninthColOpen;
                break;
            default:
                break;
        }
        return newStyle;
    };

    getDefaultStyle = col => {
        let newStyle;
        switch (col) {
            case 0:
                newStyle = style.firstCol;
                break;
            case 1:
                newStyle = style.secondCol;
                break;
            case 2:
                newStyle = style.thirdCol;
                break;
            case 3:
                newStyle = style.fourthCol;
                break;
            case 4:
                newStyle = style.fifthCol;
                break;
            case 5:
                newStyle = style.sixthCol;
                break;
            case 6:
                newStyle = style.seventhCol;
                break;
            case 7:
                newStyle = style.eighthCol;
                break;
            case 8:
                newStyle = style.ninthCol;
                break;
            default:
                break;
        }
        return newStyle;
    };

    getRowSpan = row => {
        let rowSpan;

        const section = this.getSection(row);

        rowSpan = this.getCollapsed(section);

        if (this.state.row || this.state.row === 0) {
            const clickedSection = this.getSection(this.state.row);
            if (section === clickedSection) {
                rowSpan = this.getExpanded(section);
            }
        }

        return rowSpan;
    };

    getCollapsed = section => {
        let colSpan;
        switch (section) {
            case 'Classroom Structure':
                colSpan = 5;
                break;
            case 'Instruction Management':
                colSpan = 6;
                break;
            case 'Behavior Management':
                colSpan = 7;
                break;
            case 'Classroom Climate':
                colSpan = 4;
                break;
            default:
                break;
        }

        return colSpan;
    };

    getExpanded = clickedSection => {
        let colSpan;
        switch (clickedSection) {
            case 'Classroom Structure':
                colSpan = 6;
                break;
            case 'Instruction Management':
                colSpan = 7;
                break;
            case 'Behavior Management':
                colSpan = 8;
                break;
            case 'Classroom Climate':
                colSpan = 9;
                break;
            default:
                break;
        }
        return colSpan;
    };

    getOtherLabel = (devTags, labelTag) => {
        const labelData = devTags[labelTag];

        return `Other - ${labelData}`;
    };
    // Event Handling

    onEditComplete = async (pawnId, pawnHash, deploymentId, assignmentId) => {
        const response = await Axios.post(Boilerplate.route('checkup.feedback.scoring'), {
            deploymentId,
            pawnHash,
            pawnId: +pawnId,
            assignmentId,
        });

        if (response.data.success === true) {
            const user = await this.getUserData();

            const metrics = await this.getMetricsData(user);

            const stateKeys = this.processQueryData(metrics, this.state.user);
            this.setState({
                user,
                ...stateKeys,
            });
        }
    };

    reloadUserData = async () => {
        const user = await this.getUserData();

        this.setState({
            user,
        });
    };

    onNewComplete = async (pawnId, pawnHash, deploymentId, assignmentId) => {
        // Save Data
        const response = await Axios.post(Boilerplate.route('checkup.feedback.scoring'), {
            deploymentId,
            pawnHash,
            pawnId: +pawnId,
        });

        addEvent(
            {
                eventType: 'feedbackSurveyCompleted',
                targetId: +pawnId,
                targetType: 'pawn',
                metadata: JSON.stringify({ deploymentId }),
            },
            { saveImmediately: true }
        );

        if (response.data.success === true) {
            const user = await this.getUserData();

            const metrics = await this.getMetricsData(user);

            const stateKeys = this.processQueryData(metrics, this.state.user);

            this.setState({
                user,
                ...stateKeys,
            });
        } else {
            Notification({
                message: 'Problem saving survey data',
                type: 'danger',
                duration: -1,
            });
        }

        // Save event

        return response;
    };

    reloadActivityLog = async () => {
        await this.activityLog.loadData();
    };

    handleUnload = event => {
        if (this.state.goalChanges > 0) {
            const message = 'You have unsaved changes';
            event.returnValue = message;
            return message;
        }
    };

    closeExpandables = () => {
        this.setState({
            row: null,
            col: null,
        });
    };

    handleCellClick = event => {
        let clickedCell;

        // Determine Cell
        switch (event.target.nodeName) {
            case 'path':
                clickedCell = event.target.parentElement.parentElement.parentElement.parentElement;
                break;
            case 'svg':
                clickedCell = event.target.parentElement.parentElement;
                break;
            case 'TD':
                clickedCell = event.target;
                break;
            case 'SPAN':
                clickedCell = event.target.parentElement;
                break;
            case 'g':
                clickedCell = event.target.parentElement.parentElement.parentElement;
                break;
            default:
                break;
        }

        const classArr = clickedCell.className.split(' ');
        const row = classArr[0].slice(3);
        const col = classArr[1].slice(3);
        const cell = { row, col };

        this.assignCell(cell);
    };

    handleButtonChange = () => {
        const { initialGoals, goals } = this.state;

        if (!_isEqual(initialGoals, goals)) {
            const initialArr = Object.keys(initialGoals).map(item => {
                return {
                    value: initialGoals[item],
                    key: item,
                };
            });
            const currentArr = Object.keys(goals).map(item => {
                return {
                    value: goals[item],
                    key: item,
                };
            });

            const filteredArr = currentArr.filter((item, i) => !_isEqual(item, initialArr[i]));

            this.setState({
                goalChanges: filteredArr.length,
            });
        } else {
            this.setState({
                goalChanges: 0,
            });
        }
    };

    handleGoalChange = r => {
        const { goals } = this.state;

        this.setState(
            {
                goals: {
                    ...goals,
                    [r.goalTag]: !goals[r.goalTag],
                },
            },
            () => {
                this.handleButtonChange();
            }
        );
    };

    handleOther = (array, section) => {
        let newArray = [...array];
        let last = _last(newArray);
        const index = newArray.indexOf(last);

        newArray[index] = { [section + 'Other']: last.other };

        return newArray;
    };

    handleNotesSubmit = async (value, label, devTag) => {
        const note = { value: value.notes, label };
        const {
            currentPawn: { pawnId, pawnHash },
            notesMetrics,
            user,
        } = this.state;

        const deploymentId = user.deploymentId;
        const assignmentProgressId = user.assignmentProgressId;
        const assignmentId = user.assignmentId;
        const foundMetric = notesMetrics.filter(item => item.tag.name === devTag);
        if (foundMetric.length === 0) {
            Notification({
                message: 'Problem updating notes',
                type: 'danger',
                data: `Notes metric not found for ${label} with tag ${devTag}`,
                duration: -1,
            });
            return;
        }
        const key = foundMetric[0].key.entityId + '_answer';
        const notesData = {
            sourceId: assignmentId,
            sourceType: 'assignment',
            values: JSON.stringify({ [key]: note.value }),
            pawnId,
            pawnHash,
            assignmentProgressId,
            responseSet: deploymentId,
        };

        const response = await Axios.post(
            Boilerplate.route('metrics.api.v1.data.bulkUpdate'),
            notesData
        );

        if (response.data.data.status === 'success') {
            Notification({
                message: `Notes were updated`,
                type: 'success',
                data: label,
                duration: 8000,
            });
            this.setState({
                notes: {
                    ...this.state.notes,
                    [devTag]: value.notes,
                },
            });

            return true;
        }

        Notification({
            message: 'Problem updating notes',
            type: 'danger',
            data: response && response.data.data && response.data.data.errors,
            duration: -1,
        });
    };

    handleGoalSubmit = async () => {
        const {
            goals,
            currentPawn: { pawnId, pawnHash },
            user,
        } = this.state;
        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        const deploymentId = user.deploymentId;
        const assignmentProgressId = user.assignmentProgressId;
        const assignmentId = user.assignmentId;

        const goalMetrics = this.getGoalMetrics(goals);

        const goalsData = {
            sourceId: assignmentId,
            sourceType: 'assignment',
            values: JSON.stringify(goalMetrics),
            pawnId,
            pawnHash,
            assignmentProgressId,
            responseSet: deploymentId,
        };
        const response = await Axios.post(
            Boilerplate.route('metrics.api.v1.data.bulkUpdate'),
            goalsData
        );

        if (response.data.data.status === 'success') {
            Notification({
                message: `Goals were updated`,
                type: 'success',
                duration: 5000,
            });

            // save for use in events
            let initialSet = this.state.initialGoals;

            const metrics = await this.getMetricsData(user);

            const stateKeys = this.processQueryData(metrics, user);
            this.setState(
                {
                    ...stateKeys,
                },
                () => {
                    this.handleButtonChange();
                }
            );

            // save an event for each goal that was just added
            for (const key of Object.keys(goals)) {
                const rowIndex = rows.find(item => item.goalTag === key).order;
                if (goals[key] && !initialSet[key]) {
                    addEvent(
                        {
                            eventType: 'goalSelected',
                            targetId: pawnId,
                            targetType: 'pawn',
                            metadata: JSON.stringify({
                                goal: key,
                                deploymentId,
                                assignmentProgressId,
                                row: rowIndex,
                            }),
                        },
                        { saveImmediately: true }
                    );
                    this.activityLog.loadData();
                }

                // check if the goal was just removed
                if (!goals[key] && initialSet[key]) {
                    // if so, then add an event
                    addEvent(
                        {
                            eventType: 'goalRemoved',
                            targetId: pawnId,
                            targetType: 'pawn',
                            metadata: JSON.stringify({
                                goal: key,
                                deploymentId,
                                assignmentProgressId,
                                row: rowIndex,
                            }),
                        },
                        { saveImmediately: true }
                    );
                    this.activityLog.loadData();
                }
            }
        } else {
            Notification({
                message: 'Problem updating goals',
                type: 'danger',
                data: response && response.data.data && response.data.data.errors,
                duration: -1,
            });
        }
    };

    handleShareChange = () => {
        this.setState(
            state => ({ shareReport: !state.shareReport }),
            () => {
                this.saveShareChange();
            }
        );
    };

    // Renders
    renderCheckupTable = () => {
        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        const tableData = this.processCheckUpData(rows, this.state);

        // Separate Table Data
        const structureTable = tableData.slice(0, 5);
        const instructionTable = tableData.slice(5, 11);
        const behaviorTable = tableData.slice(11, 18);
        const climateTable = tableData.slice(18, 23);

        return (
            <div className={style.tableWrapper}>
                <table className={`${style.table} table`}>
                    <thead className={style.tableHead}>
                        <tr className="row">
                            <th
                                style={{ border: 'none', backgroundColor: '#fff', width: '3.5%' }}
                            />
                            <th className={`text-center ${style.goalCol}`}> Goal </th>
                            <th className={`text-center ${style.notesCol}`}>Notes</th>
                            <th className={`text-center ${style.areaCol}`}>Area to Improve</th>
                            <th
                                colSpan="6"
                                style={{
                                    whiteSpace: 'nowrap',
                                    borderTopRightRadius: '15px',
                                    borderRight: 'none',
                                }}
                            >
                                <div className={style.arrowBox}>
                                    <span>Needs Attention</span>
                                    <img
                                        src={ArrowSVG}
                                        style={{ width: '45%' }}
                                        alt="Arrow goes here."
                                    />
                                    <span>Strength</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableSection(structureTable, 0)}
                        {this.renderTableSection(instructionTable, 5)}
                        {this.renderTableSection(behaviorTable, 11)}
                        {this.renderTableSection(climateTable, 18)}
                    </tbody>
                </table>
            </div>
        );
    };

    renderTableSection = (tableData, addIndex) => {
        const tableSection = tableData.map((item, index) => {
            return (
                <React.Fragment key={`${index + addIndex}`}>
                    <tr
                        className={`row-${index + addIndex}`}
                        style={{ paddingBottom: '10px' }}
                        key={`row-${index + addIndex}`}
                    >
                        {item.map((cell, i) => {
                            const htmlCell = this.getCell(cell, index + addIndex, i, index);
                            if (i === 9) {
                                return;
                            }
                            if (index === 0 && i === 0 && addIndex === 0) {
                                return (
                                    <React.Fragment key={`${addIndex + index} ${i}`}>
                                        <td
                                            rowSpan={this.getRowSpan(index + addIndex)}
                                            className={style.sideTab}
                                        >
                                            <span className={style.structureText}>
                                                Classroom Structure
                                                <span className={style.triangle}>
                                                    <BrowserOnly>
                                                        <Triangle />
                                                    </BrowserOnly>
                                                </span>
                                            </span>
                                        </td>
                                        {htmlCell}
                                    </React.Fragment>
                                );
                            }
                            if (index === 0 && i === 0 && addIndex === 5) {
                                return (
                                    <React.Fragment key={`${addIndex + index} ${i}`}>
                                        <td
                                            rowSpan={this.getRowSpan(index + addIndex)}
                                            className={style.sideTab}
                                        >
                                            <span className={style.instructionText}>
                                                Instruction Management
                                                <span className={style.square}>
                                                    <BrowserOnly>
                                                        <Square />
                                                    </BrowserOnly>
                                                </span>
                                            </span>
                                        </td>
                                        {htmlCell}
                                    </React.Fragment>
                                );
                            }
                            if (index === 0 && i === 0 && addIndex === 11) {
                                return (
                                    <React.Fragment key={`${addIndex + index} ${i}`}>
                                        <td
                                            rowSpan={this.getRowSpan(index + addIndex)}
                                            className={style.sideTab}
                                        >
                                            <span className={style.behaviorText}>
                                                Behavior Management
                                                <span className={style.circle}>
                                                    <BrowserOnly>
                                                        <Circle />
                                                    </BrowserOnly>
                                                </span>
                                            </span>
                                        </td>
                                        {htmlCell}
                                    </React.Fragment>
                                );
                            }
                            if (index === 0 && i === 0 && addIndex === 18) {
                                return (
                                    <React.Fragment key={`${addIndex + index} ${i}`}>
                                        <td
                                            rowSpan={this.getRowSpan(index + addIndex)}
                                            className={style.sideTab}
                                        >
                                            <span className={style.climateText}>
                                                Classroom Climate
                                                <span className={style.diamond}>
                                                    <BrowserOnly>
                                                        <Diamond />
                                                    </BrowserOnly>
                                                </span>
                                            </span>
                                        </td>
                                        {htmlCell}
                                    </React.Fragment>
                                );
                            }
                            return (
                                <React.Fragment key={`${addIndex + index} ${i}`}>
                                    {htmlCell}
                                </React.Fragment>
                            );
                        })}
                    </tr>

                    {this.state.row === index + addIndex ? (
                        <RowDrop messages={item[9]} col={this.state.col} />
                    ) : null}
                </React.Fragment>
            );
        });

        return tableSection;
    };

    renderNotesPrintable = state => {
        const { notes, user } = state;
        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        const notesArr = rows.map(item => {
            return {
                label: item.label,
                value: notes[item.notesTag],
                row: item.order,
                labelTag: item.labelTag,
            };
        });

        let field = 'Teacher: ';

        const displayNotes = notesArr.filter(item => item.value !== null && item.value !== '');

        const classroomStructureNotes = displayNotes.filter(
            item => this.determineSection(item.row) === 'Classroom Structure'
        );
        const instructionManagementNotes = displayNotes.filter(
            item => this.determineSection(item.row) === 'Instruction Management'
        );
        const behaviorManagementNotes = displayNotes.filter(
            item => this.determineSection(item.row) === 'Behavior Management'
        );
        const classromClimateNotes = displayNotes.filter(
            item => this.determineSection(item.row) === 'Classroom Climate'
        );

        return (
            <PrintOnly>
                <div className={style.printHeaderNotes}>
                    <div className={style.feedbackLabel}>
                        <div className={style.headerTitleNotes}>My Check-Up Feedback Report</div>
                        <span>
                            <span className={style.bold}>{field}</span>
                            <span>{user.label}</span>
                        </span>
                    </div>
                    <div className={style.feedbackDateBox}>
                        <div style={{ marginBottom: '10px' }}>
                            <span className={style.bold}>Feedback Survey Completed: </span>
                            <span style={{ marginLeft: '.25em' }}>{state.completedDate}</span>
                        </div>
                        <div className={style.coachLabel}>
                            <span className={style.bold}>Coach: </span>

                            <span style={{ marginLeft: '.5em' }}>
                                {user.coach && user.coach.name}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={style.noteSection}>
                    <div className={`${style.bold}`}>
                        <span className={style.notesTriangle}>
                            <Triangle />
                        </span>
                        Classroom Structure Notes:
                    </div>
                    <div>
                        {classroomStructureNotes.map((item, i) => {
                            let label = item.label;

                            if (item.label.includes('Other')) {
                                label = this.getOtherLabel(state.devTags, item.labelTag);
                            }
                            return (
                                <div key={i} className={style.notePrintBox}>
                                    <div>{label}:</div>
                                    <div className={style.noteText}>{item.value}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={style.noteSection}>
                    <div className={`${style.bold}`}>
                        <span className={style.noteSquare}>
                            <Square />
                        </span>
                        Instruction Management Notes:
                    </div>
                    <div>
                        {instructionManagementNotes.map((item, i) => {
                            let label = item.label;

                            if (item.label.includes('Other')) {
                                label = this.getOtherLabel(state.devTags, item.labelTag);
                            }
                            return (
                                <div key={i} className={style.notePrintBox}>
                                    <div>{label}:</div>
                                    <div className={style.noteText}>{item.value}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={style.noteSection}>
                    <div className={`${style.bold}`}>
                        <span className={style.notesCircle}>
                            <Circle />
                        </span>
                        Behavior Management Notes:
                    </div>
                    <div>
                        {behaviorManagementNotes.map((item, i) => {
                            let label = item.label;

                            if (item.label.includes('Other')) {
                                label = this.getOtherLabel(state.devTags, item.labelTag);
                            }
                            return (
                                <div key={i} className={style.notePrintBox}>
                                    <div>{label}:</div>
                                    <div className={style.noteText}>{item.value}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={style.noteSection}>
                    <div className={`${style.bold}`}>
                        <span className={style.notesDiamond}>
                            <Diamond />
                        </span>
                        Classroom Climate Notes:
                    </div>
                    <div>
                        {classromClimateNotes.map((item, i) => {
                            let label = item.label;

                            if (item.label.includes('Other')) {
                                label = this.getOtherLabel(state.devTags, item.labelTag);
                            }
                            return (
                                <div key={i} className={style.notePrintBox}>
                                    <div>{label}:</div>
                                    <div className={style.noteText}>{item.value}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </PrintOnly>
        );
    };

    renderPrintHeader = state => {
        const { user } = state;

        let field = 'Teacher: ';

        return (
            <PrintOnly>
                <div className={style.printHeader}>
                    <div className={style.feedbackLabel}>
                        <div className={style.headerTitle}>My Check-Up Feedback Report </div>
                        <span>
                            <span className={style.bold}>{field}</span>
                            <span>{user.label}</span>
                        </span>
                    </div>
                    <div className={style.feedbackDateBox}>
                        <div style={{ marginBottom: '10px' }}>
                            <span className={style.bold}>Feedback Survey Completed: </span>
                            <span style={{ marginLeft: '.25em' }}>{state.completedDate}</span>
                        </div>
                        <div className={style.coachLabel}>
                            <span className={style.bold}>Coach: </span>
                            <span style={{ marginLeft: '.5em' }}>
                                {user.coach && user.coach.name}
                            </span>
                        </div>
                    </div>
                </div>
            </PrintOnly>
        );
    };

    renderSurveyButton = () => {
        const { user } = this.state;

        // submitted by coach
        if (user.surveyInProgress) {
            if (user.progressSubmittedBy === 'coach') {
                // console.log('IP/C');

                if (!user.isExpired) {
                    return (
                        <>
                            <div className={style.startedBy}>
                                <Tooltip
                                    title="<p>This survey was started by your coach. New surveys may be started 30 days after the date the previous survey is started.</p>"
                                    size="medium"
                                    placement="bottom"
                                >
                                    <span style={{ fontWeight: 'bold' }}>
                                        <sup>
                                            <Info />
                                        </sup>{' '}
                                        Survey started by coach. Expires on:{' '}
                                    </span>
                                </Tooltip>
                                {user.expirationDate}
                            </div>
                            <StartSurvey
                                onNewClose={this.reloadUserData}
                                onComplete={this.onNewComplete}
                                pawn={this.state.currentPawn}
                                progress={user}
                                disabled
                            />
                        </>
                    );
                }

                return (
                    <StartSurvey
                        onNewClose={this.reloadUserData}
                        onComplete={this.onNewComplete}
                        pawn={this.state.currentPawn}
                        progress={user}
                    />
                );
            }
            // console.log('IP/T');

            return (
                <>
                    {user.expirationDate && (
                        <div className={style.expirationDate}>
                            {' '}
                            <Tooltip
                                title="<p>You may edit the current survey until this date. Surveys are editable for 30 days from the date they are started.</p>"
                                size="medium"
                                placement="bottom"
                            >
                                <span style={{ fontWeight: 'bold' }}>
                                    <sup>
                                        <Info />
                                    </sup>{' '}
                                    Survey Editable Until:{' '}
                                </span>
                            </Tooltip>
                            {user.expirationDate}
                        </div>
                    )}
                    <StartSurvey
                        onNewClose={this.reloadUserData}
                        onComplete={this.onNewComplete}
                        onEvalSuccess={this.onEvalSuccess}
                        pawn={this.state.currentPawn}
                        progress={user}
                    />
                </>
            );
        }

        if (!user.surveyInProgress) {
            if (user.completedSubmittedBy === 'coach') {
                // console.log('NIP/C');

                if (user.completedDate && !user.isExpired) {
                    return (
                        <>
                            <div className={style.startedBy}>
                                <Tooltip
                                    title="<p>This survey was started by your coach. New surveys may be started 30 days after the date the previous survey is started.</p>"
                                    size="medium"
                                    placement="bottom"
                                >
                                    <span style={{ fontWeight: 'bold' }}>
                                        <sup>
                                            <Info />
                                        </sup>{' '}
                                        Survey started by coach. Expires on:{' '}
                                    </span>
                                </Tooltip>
                                {user.expirationDate}
                            </div>
                            <StartSurvey
                                onNewClose={this.reloadUserData}
                                onComplete={this.onNewComplete}
                                pawn={this.state.currentPawn}
                                progress={user}
                                disabled
                            />
                        </>
                    );
                }

                if (user.isNew || user.isExpired) {
                    return (
                        <StartSurvey
                            onNewClose={this.reloadUserData}
                            onComplete={this.onNewComplete}
                            pawn={this.state.currentPawn}
                            progress={user}
                        />
                    );
                }
            } else {
                if (user.completedDate && !user.isExpired) {
                    return (
                        <>
                            {user.expirationDate && (
                                <div className={style.expirationDate}>
                                    {' '}
                                    <Tooltip
                                        title="<p>You may edit the current survey until this date. Surveys are editable for 30 days from the date they are started.</p>"
                                        size="medium"
                                        placement="bottom"
                                    >
                                        <span style={{ fontWeight: 'bold' }}>
                                            <sup>
                                                <Info />
                                            </sup>{' '}
                                            Survey Editable Until:{' '}
                                        </span>
                                    </Tooltip>
                                    {user.expirationDate}
                                </div>
                            )}
                            <EditSurvey
                                onComplete={this.onEditComplete}
                                pawn={this.state.currentPawn}
                                progress={user}
                            />
                        </>
                    );
                }
                if (user.isNew || user.isExpired) {
                    return (
                        <>
                            <StartSurvey
                                onNewClose={this.reloadUserData}
                                onComplete={this.onNewComplete}
                                pawn={this.state.currentPawn}
                                progress={user}
                            />
                        </>
                    );
                }
            }
        }
    };

    renderRelationships = () => {
        const { user } = this.state;

        return (
            <div className={style.relations}>
                <ViewLinks
                    user={{ pawnId: user.value, ...user.userData }}
                    pendingRequests={user.pendingInvites}
                    parentPawns={user.parentPawns}
                    reloadUserData={this.reloadUserData}
                />
            </div>
        );
    };

    renderCompletedBy = () => {
        const {
            user: { completedSubmittedBy, label, eventList, deploymentId },
        } = this.state;

        let displayLabel = '';

        if (completedSubmittedBy === 'coach') {
            const match = eventList.find(item => {
                const metaData = JSON.parse(item.metadata);

                if (metaData.deploymentId === deploymentId) {
                    return item;
                }
                return undefined;
            });

            if (match) {
                displayLabel = match.pawn.user.fullName;
            }
        } else if (completedSubmittedBy === 'teacher') {
            displayLabel = `${label} (Teacher)`;
        }

        return (
            <span>
                <strong>Completed By: </strong>
                <span style={{ fontWeight: '400' }}>{displayLabel}</span>
            </span>
        );
    };

    getTabList = () => {
        const { user } = this.state;
        const tabList = [
            {
                name: 'checkupFeedback',
                title: 'My Check-Up Feedback',
                content: (
                    <>
                        {!this.state.noDevTags && (
                            <div className={style.actionRow}>
                                <div className={style.selectRow}>
                                    {this.state.completedDate && (
                                        <>
                                            {' '}
                                            <span>
                                                Feedback Survey Completed:{' '}
                                                <span className={style.completedDate}>
                                                    {this.state.completedDate}
                                                </span>
                                            </span>
                                            {this.renderCompletedBy()}
                                        </>
                                    )}
                                </div>

                                <div className={style.actionBox}>
                                    <div className={style.print}>
                                        <button
                                            type="button"
                                            className={`btn btn-default ${style.printButton}`}
                                            onClick={this.downloadPdf}
                                        >
                                            Download PDF
                                        </button>
                                    </div>
                                    <a
                                        className={`btn btn-default ${style.printButton}`}
                                        download
                                        href="https://app.classroomcheckup.org/api/resources/file/public/CP3-Blank_Check-Up_Feedback.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        View Blank Feedback Survey
                                    </a>
                                    {this.state.goalChanges > 0 ? (
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            style={{ color: '#fff' }}
                                            onClick={this.handleGoalSubmit}
                                        >
                                            {`Update Goals (${this.state.goalChanges})`}
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-default disabled"
                                            style={{ backgroundColor: '#458dcb', color: '#fff' }}
                                            onClick={this.handleGoalSubmit}
                                        >
                                            Update Goals
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className={style.printWrapper}>
                            {!this.state.noDevTags && (
                                <Report
                                    width="100%"
                                    showPagination={false}
                                    onRef={element => {
                                        this.report = element;
                                    }}
                                    downloadFilename={`Check-UpFeedbackReport_${user.label}_${this.state.completedDate}`}
                                >
                                    <Page>
                                        {this.renderPrintHeader(this.state)}
                                        {this.renderCheckupTable()}{' '}
                                    </Page>
                                    <Page>{this.renderNotesPrintable(this.state)}</Page>
                                </Report>
                            )}
                        </div>
                        {this.renderRelationships()}
                        <hr />
                        <div
                            className={
                                this.state.noDevTags
                                    ? style.buttonContainerNoData
                                    : style.buttonContainer
                            }
                        >
                            {this.renderSurveyButton()}
                            {this.state.noDevTags && (
                                <a
                                    className={`btn btn-default ${style.printButton}`}
                                    download
                                    href="https://app.classroomcheckup.org/api/resources/file/public/CP3-Blank_Check-Up_Feedback.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    View Blank Feedback Survey
                                </a>
                            )}
                        </div>
                    </>
                ),
            },
            {
                name: 'planningForm',
                title: 'CCU Planning Form',
                content: (
                    <div>
                        <InterventionPlanning
                            timestamps={this.state.timestamps}
                            loadData={this.reloadActivityLog}
                            noDevTags={this.state.noDevTags}
                            selectedPerson={this.state.user}
                            goals={this.state.goals}
                        />
                    </div>
                ),
            },
        ];

        return tabList;
    };

    render() {
        if (this.state.loading) {
            return <Loader loading />;
        }
        const {
            content: {
                checkUpTable: { rows, metricsQuery },
            },
        } = this.props;
        const tabList = this.getTabList();

        return (
            <div className={style.pageWrapper}>
                {/* Goals Box */}

                <Goals
                    determineSection={this.determineSection}
                    user={this.state.user}
                    goals={this.state.initialGoals}
                    rows={rows}
                    timestamps={this.state.timestamps}
                    devTags={this.state.devTags}
                    noDevTags={this.state.noDevTags}
                />
                <div className={style.tabWrapper}>
                    <Tabs tabList={tabList} saveInHash />
                </div>

                <div
                    className={
                        !this.state.noDevTags
                            ? `${style.activityLogBox}`
                            : `${style.activityLogBoxNoData}`
                    }
                >
                    <ActivityLog
                        ref={element => {
                            this.activityLog = element;
                        }}
                        goals={this.state.goals}
                        devTags={this.state.devTags}
                        determineSection={this.determineSection}
                        closeExpandables={this.closeExpandables}
                        rows={rows}
                        metricsQuery={metricsQuery}
                        user={this.state.user}
                        processGoals={this.processGoals}
                        getGoalsFromProps={this.getGoalsFromProps}
                        renderTableSection={this.renderTableSection}
                        renderPrintHeader={this.renderPrintHeader}
                        processCheckUpData={this.processCheckUpData}
                        renderNotes={this.renderNotesPrintable}
                    />
                </div>
            </div>
        );
    }
}
