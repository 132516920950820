import React from 'react';
import PropTypes from 'prop-types';

import style from './style.css';

const RowDrop = props => {
    const determineMessage = cell => {
        const { col, messages } = props;

        if (messages.length > 0) {
            const match = messages.find(item => item.column + 2 === +col);

            return match;
        }
    };

    const buildMessage = match => {
        const matchList = match.bullets;

        const colors = ['#EDA196', '#EABD91', '#EFD991', '#E3E697', '#BDEAAF', '#9AD0AA'];

        return { matchList, color: colors[match.column - 1] };
    };

    const getList = () => {
        const match = determineMessage();

        const result = buildMessage(match);

        const columnStyle = result.matchList.length > 1 ? style.twoColumns : style.oneColumn;

        const HTMLList = (
            <tr className={style.rowDrop}>
                <td colSpan={9} style={{ border: `5px solid ${result.color}` }}>
                    <ul className={columnStyle}>
                        {result.matchList.map((item, i) => (
                            <li key={i}>{item}</li>
                        ))}
                    </ul>
                </td>
            </tr>
        );

        return HTMLList;
    };

    return getList();
};

RowDrop.propTypes = {
    messages: PropTypes.array,
    col: PropTypes.number,
};

export default RowDrop;
