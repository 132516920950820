import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo, gql } from 'cccisd-apollo';
import { Report, Page, PrintOnly } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { addEvent } from 'cccisd-laravel-eventlog';
import ArrowClosed from 'cccisd-icons/arrow-right20';
import ArrowOpen from 'cccisd-icons/arrow-down20';
import Notification from 'cccisd-notification';
import PlanningSurvey from './PlanningSurvey';
import GoalItem from './GoalItem';
import Considerations from './Considerations';
import style from './style.css';
import variableQuery from './variableQuery.graphql';
import format from 'date-fns/format';

const goals = [
    {
        id: 1,
        handle: 'PhysLay',
        name: 'Physical Layout',
        variables: [
            'p_phys_lay_strat',
            'p_phys_lay_action1',
            'p_phys_lay_action2',
            'p_phys_lay_action3',
            'p_phys_lay_action4',
            'p_phys_lay_monitor1',
            'p_phys_lay_monitor2',
            'p_phys_lay_monitor3',
            'p_phys_lay_monitor4',
            'p_phys_lay_eval1',
            'p_phys_lay_eval2',
            'p_phys_lay_eval3',
            'p_phys_lay_eval4',
        ],
        props: { color: '#8B4195', section: 'Classroom Structure' },
        condition: variableValues => {
            return variableValues.p_phys_lay_strat === '1';
        },
        strategy: () => {
            return ['Physical Classroom Structure'];
        },
        timestamp: 'physical_layout_goal',
        component: GoalItem,
    },
    {
        id: 2,
        handle: 'ClassRules',
        name: 'Classroom Rules',
        variables: [
            'p_class_rules_strat',
            'p_class_rules_action1',
            'p_class_rules_action2',
            'p_class_rules_action3',
            'p_class_rules_action4',
            'p_class_rules_monitor1',
            'p_class_rules_monitor2',
            'p_class_rules_monitor3',
            'p_class_rules_monitor4',
            'p_class_rules_eval1',
            'p_class_rules_eval2',
            'p_class_rules_eval3',
            'p_class_rules_eval4',
        ],
        props: { color: '#8B4195', section: 'Classroom Structure' },
        condition: variableValues => {
            return variableValues.p_class_rules_strat === '1';
        },

        strategy: () => {
            return ['Defining and Teaching Classroom Rules'];
        },
        component: GoalItem,
        timestamp: 'classroom_rules_goal',
    },
    {
        id: 3,
        handle: 'ClassRoutines',
        name: 'Classroom Routines',
        variables: [
            'p_class_routines_strat',
            'p_class_routines_action1',
            'p_class_routines_action2',
            'p_class_routines_action3',
            'p_class_routines_action4',
            'p_class_routines_monitor1',
            'p_class_routines_monitor2',
            'p_class_routines_monitor3',
            'p_class_routines_monitor4',
            'p_class_routines_eval1',
            'p_class_routines_eval2',
            'p_class_routines_eval3',
            'p_class_routines_eval4',
        ],
        props: { color: '#8B4195', section: 'Classroom Structure' },
        condition: variableValues => {
            return variableValues.p_class_routines_strat === '1';
        },

        strategy: () => {
            return ['Teaching Classroom Routines'];
        },
        component: GoalItem,
        timestamp: 'classroom_routines_goal',
    },
    {
        id: 4,
        handle: 'SmoothTrans',
        name: 'Smooth Transitions',
        variables: [
            'p_smooth_trans_strat1',
            'p_smooth_trans_strat2',
            'p_smooth_trans_strat3',
            'p_smooth_trans_strat0',
            'p_smooth_trans_action1',
            'p_smooth_trans_action2',
            'p_smooth_trans_action3',
            'p_smooth_trans_action4',
            'p_smooth_trans_monitor1',
            'p_smooth_trans_monitor2',
            'p_smooth_trans_monitor3',
            'p_smooth_trans_monitor4',
            'p_smooth_trans_eval1',
            'p_smooth_trans_eval2',
            'p_smooth_trans_eval3',
            'p_smooth_trans_eval4',
        ],
        props: { color: '#8B4195', section: 'Classroom Structure' },
        condition: variableValues => {
            return (
                variableValues.p_smooth_trans_strat1 === '1' ||
                variableValues.p_smooth_trans_strat2 === '1' ||
                variableValues.p_smooth_trans_strat3 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_smooth_trans_strat1 === '1') {
                strategies.push('Posting and Using a Schedule');
            }
            if (variableValues.p_smooth_trans_strat2 === '1') {
                strategies.push('Teaching Classroom Routines');
            }
            if (variableValues.p_smooth_trans_strat3 === '1') {
                strategies.push('Using an Attention Signal');
            }
            return strategies;
        },
        component: GoalItem,
        timestamp: 'smooth_transitions_goal',
    },
    {
        id: 5,
        handle: 'OtherClassStruc',
        name: 'Other Classroom Structure',
        variables: [
            'p_other_class_struc_strat1',
            'p_other_class_struc_strat2',
            'p_other_class_struc_strat2a',
            'p_other_class_struc_strat2b',
            'p_other_class_struc_strat2c',
            'p_other_class_struc_strat2d',
            'p_other_class_struc_strat2e',
            'p_other_class_struc_action1',
            'p_other_class_struc_action2',
            'p_other_class_struc_action3',
            'p_other_class_struc_action4',
            'p_other_class_struc_monitor1',
            'p_other_class_struc_monitor2',
            'p_other_class_struc_monitor3',
            'p_other_class_struc_monitor4',
            'p_other_class_struc_eval1',
            'p_other_class_struc_eval2',
            'p_other_class_struc_eval3',
            'p_other_class_struc_eval4',
        ],
        props: { color: '#8B4195', section: 'Classroom Structure' },
        condition: variableValues => {
            return (
                variableValues.p_other_class_struc_strat1 &&
                variableValues.p_other_class_struc_strat1 !== '0'
            );
        },
        strategy: variableValues => {
            let options;
            let firstStrat;
            let secondStratVar;
            let secondStrat;

            switch (variableValues.p_other_class_struc_strat1) {
                case '1':
                    firstStrat = 'Defining and Teaching Classroom Rules';
                    options = {
                        '1': 'Physical Classroom Structure',
                        '2': 'Posting and Using a Schedule',
                        '3': 'Teaching Classroom Routines',
                        '4': 'Using an Attention Signal',
                    };
                    secondStratVar = 'p_other_class_struc_strat2a';

                    break;
                case '2':
                    firstStrat = 'Physical Classroom Structure';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Posting and Using a Schedule',
                        '3': 'Teaching Classroom Routines',
                        '4': 'Using an Attention Signal',
                    };
                    secondStratVar = 'p_other_class_struc_strat2b';
                    break;
                case '3':
                    firstStrat = 'Posting and Using a Schedule';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Physical Classroom Structure',
                        '3': 'Teaching Classroom Routines',
                        '4': 'Using an Attention Signal',
                    };
                    secondStratVar = 'p_other_class_struc_strat2c';
                    break;
                case '4':
                    firstStrat = 'Teaching Classroom Routines';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Physical Classroom Structure',
                        '3': 'Posting and Using a Schedule',
                        '4': 'Using an Attention Signal',
                    };
                    secondStratVar = 'p_other_class_struc_strat2d';
                    break;
                case '5':
                    firstStrat = 'Using an Attention Signal';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Physical Classroom Structure',
                        '3': 'Posting and Using a Schedule',
                        '4': 'Teaching Classroom Routines',
                    };
                    secondStratVar = 'p_other_class_struc_strat2e';
                    break;

                default:
                    break;
            }

            if (firstStrat) {
                if (variableValues.p_other_class_struc_strat2 === '1') {
                    secondStrat = options[variableValues[secondStratVar]];
                }
                if (secondStrat) {
                    return [firstStrat, secondStrat];
                }
                return [firstStrat];
            }
            return [];
        },
        component: GoalItem,
        timestamp: 'classroom_structure_other_goal',
    },
    {
        id: 6,
        handle: 'SchedPosted',
        name: 'Schedule Posted and Followed',
        variables: [
            'p_sched_posted_strat',
            'p_sched_posted_action1',
            'p_sched_posted_action2',
            'p_sched_posted_action3',
            'p_sched_posted_action4',
            'p_sched_posted_monitor1',
            'p_sched_posted_monitor2',
            'p_sched_posted_monitor3',
            'p_sched_posted_monitor4',
            'p_sched_posted_eval1',
            'p_sched_posted_eval2',
            'p_sched_posted_eval3',
            'p_sched_posted_eval4',
        ],
        props: { color: '#D78601', section: ' Instruction Management' },
        condition: variableValues => {
            return variableValues.p_sched_posted_strat === '1';
        },
        strategy: () => {
            return ['Posting and Using a Schedule'];
        },
        component: GoalItem,
        timestamp: 'schedule_goal',
    },
    {
        id: 7,
        handle: 'AcademicObj',
        name: 'Academic Objectives',
        variables: [
            'p_academic_obj_strat',
            'p_academic_obj_action1',
            'p_academic_obj_action2',
            'p_academic_obj_action3',
            'p_academic_obj_action4',
            'p_academic_obj_monitor1',
            'p_academic_obj_monitor2',
            'p_academic_obj_monitor3',
            'p_academic_obj_monitor4',
            'p_academic_obj_eval1',
            'p_academic_obj_eval2',
            'p_academic_obj_eval3',
            'p_academic_obj_eval4',
        ],
        props: { color: '#D78601', section: ' Instruction Management' },
        condition: variableValues => {
            return variableValues.p_academic_obj_strat === '1';
        },
        strategy: () => {
            return ['Developing and Using Clear Academic Objectives'];
        },
        component: GoalItem,
        timestamp: 'academic_objectives_goal',
    },
    {
        id: 8,
        handle: 'Pacing',
        name: 'Pacing of Instruction',
        variables: [
            'p_pacing_strat',
            'p_pacing_action1',
            'p_pacing_action2',
            'p_pacing_action3',
            'p_pacing_action4',
            'p_pacing_monitor1',
            'p_pacing_monitor2',
            'p_pacing_monitor3',
            'p_pacing_monitor4',
            'p_pacing_eval1',
            'p_pacing_eval2',
            'p_pacing_eval3',
            'p_pacing_eval4',
        ],
        props: { color: '#D78601', section: ' Instruction Management' },
        condition: variableValues => {
            return variableValues.p_pacing_strat === '1';
        },
        strategy: () => {
            return ['Increasing Opportunities to Respond'];
        },
        component: GoalItem,
        timestamp: 'pacing_instruction_goal',
    },
    {
        id: 9,
        handle: 'StudAccuracy',
        name: 'Student Accuracy',
        variables: [
            'p_stud_accuracy_strat',
            'p_stud_accuracy_action1',
            'p_stud_accuracy_action2',
            'p_stud_accuracy_action3',
            'p_stud_accuracy_action4',
            'p_stud_accuracy_monitor1',
            'p_stud_accuracy_monitor2',
            'p_stud_accuracy_monitor3',
            'p_stud_accuracy_monitor4',
            'p_stud_accuracy_eval1',
            'p_stud_accuracy_eval2',
            'p_stud_accuracy_eval3',
            'p_stud_accuracy_eval4',
        ],
        props: { color: '#D78601', section: ' Instruction Management' },
        condition: variableValues => {
            return variableValues.p_stud_accuracy_strat === '1';
        },
        strategy: () => {
            return ['Providing Academic Feedback'];
        },
        component: GoalItem,
        timestamp: 'student_accuracy_goal',
    },
    {
        id: 10,
        handle: 'StudEngage',
        name: 'Student Engagement',
        variables: [
            'p_stud_engage_strat1',
            'p_stud_engage_strat2',
            'p_stud_engage_strat3',
            'p_stud_engage_strat0',
            'p_stud_engage_action1',
            'p_stud_engage_action2',
            'p_stud_engage_action3',
            'p_stud_engage_action4',
            'p_stud_engage_monitor1',
            'p_stud_engage_monitor2',
            'p_stud_engage_monitor3',
            'p_stud_engage_monitor4',
            'p_stud_engage_eval1',
            'p_stud_engage_eval2',
            'p_stud_engage_eval3',
            'p_stud_engage_eval4',
        ],
        props: { color: '#D78601', section: ' Instruction Management' },
        condition: variableValues => {
            return (
                variableValues.p_stud_engage_strat1 === '1' ||
                variableValues.p_stud_engage_strat2 === '1' ||
                variableValues.p_stud_engage_strat3 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_stud_engage_strat1 === '1') {
                strategies.push('Developing and Using Clear Academic Objectives');
            }
            if (variableValues.p_stud_engage_strat2 === '1') {
                strategies.push('Increasing Opportunities to Respond');
            }
            if (variableValues.p_stud_engage_strat3 === '1') {
                strategies.push('Providing Academic Feedback');
            }
            return strategies;
        },
        component: GoalItem,
        timestamp: 'student_engagement_goal',
    },
    {
        id: 11,
        handle: 'OtherInstructionMgt',
        name: 'Other Instruction Management',
        variables: [
            'p_other_instruct_mgmt_strat1',
            'p_other_instruct_mgmt_strat2',
            'p_other_instruct_mgmt_strat2a',
            'p_other_instruct_mgmt_strat2b',
            'p_other_instruct_mgmt_strat2c',
            'p_other_instruct_mgmt_strat2d',
            'p_other_instruct_mgmt_action1',
            'p_other_instruct_mgmt_action2',
            'p_other_instruct_mgmt_action3',
            'p_other_instruct_mgmt_action4',
            'p_other_instruct_mgmt_monitor1',
            'p_other_instruct_mgmt_monitor2',
            'p_other_instruct_mgmt_monitor3',
            'p_other_instruct_mgmt_monitor4',
            'p_other_instruct_mgmt_eval1',
            'p_other_instruct_mgmt_eval2',
            'p_other_instruct_mgmt_eval3',
            'p_other_instruct_mgmt_eval4',
        ],
        props: { color: '#D78601', section: 'Instruction Management' },
        condition: variableValues => {
            return (
                variableValues.p_other_instruct_mgmt_strat1 &&
                variableValues.p_other_instruct_mgmt_strat1 !== '0'
            );
        },
        strategy: variableValues => {
            let options;
            let firstStrat;
            let secondStratVar;
            let secondStrat;

            switch (variableValues.p_other_instruct_mgmt_strat1) {
                case '1':
                    firstStrat = 'Developing and Using Clear Academic Objectives';
                    options = {
                        '1': 'Increasing Opportunities to Respond',
                        '2': 'Posting and Using a Schedule',
                        '3': 'Providing Academic Feedback',
                    };
                    secondStratVar = 'p_other_instruct_mgmt_strat2a';

                    break;
                case '2':
                    firstStrat = 'Increasing Opportunities to Respond';
                    options = {
                        '1': 'Developing and Using Clear Academic Objectives',
                        '2': 'Posting and Using a Schedule',
                        '3': 'Providing Academic Feedback',
                    };
                    secondStratVar = 'p_other_instruct_mgmt_strat2b';
                    break;
                case '3':
                    firstStrat = 'Posting and Using a Schedule';
                    options = {
                        '1': 'Developing and Using Clear Academic Objectives',
                        '2': 'Increasing Opportunities to Respond',
                        '3': 'Providing Academic Feedback',
                    };
                    secondStratVar = 'p_other_instruct_mgmt_strat2c';
                    break;
                case '4':
                    firstStrat = 'Providing Academic Feedback';
                    options = {
                        '1': 'Developing and Using Clear Academic Objectives',
                        '2': 'Increasing Opportunities to Respond',
                        '3': 'Posting and Using a Schedule',
                    };
                    secondStratVar = 'p_other_instruct_mgmt_strat2d';
                    break;

                default:
                    break;
            }

            if (firstStrat) {
                if (variableValues.p_other_instruct_mgmt_strat2 === '1') {
                    secondStrat = options[variableValues[secondStratVar]];
                }
                if (secondStrat) {
                    return [firstStrat, secondStrat];
                }
                return [firstStrat];
            }
            return [];
        },
        component: GoalItem,
        timestamp: 'instruction_management_other_goal',
    },
    {
        id: 12,
        handle: 'BehavioralExpect',
        name: 'Behavioral Expectations',
        variables: [
            'p_behave_expect_strat1',
            'p_behave_expect_strat2',
            'p_behave_expect_strat3',
            'p_behave_expect_strat4',
            'p_behave_expect_strat5',
            'p_behave_expect_strat0',
            'p_behave_expect_action1',
            'p_behave_expect_action2',
            'p_behave_expect_action3',
            'p_behave_expect_action4',
            'p_behave_expect_monitor1',
            'p_behave_expect_monitor2',
            'p_behave_expect_monitor3',
            'p_behave_expect_monitor4',
            'p_behave_expect_eval1',
            'p_behave_expect_eval2',
            'p_behave_expect_eval3',
            'p_behave_expect_eval4',
        ],
        props: { color: '#3B6028', section: 'Behavior Management' },
        condition: variableValues => {
            return (
                variableValues.p_behave_expect_strat1 === '1' ||
                variableValues.p_behave_expect_strat2 === '1' ||
                variableValues.p_behave_expect_strat3 === '1' ||
                variableValues.p_behave_expect_strat4 === '1' ||
                variableValues.p_behave_expect_strat5 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_behave_expect_strat1 === '1') {
                strategies.push('Defining and Teaching Classroom Rules');
            }
            if (variableValues.p_behave_expect_strat2 === '1') {
                strategies.push('Teaching Behavior Expectations');
            }
            if (variableValues.p_behave_expect_strat3 === '1') {
                strategies.push('Teaching Classroom Routines');
            }
            if (variableValues.p_behave_expect_strat4 === '1') {
                strategies.push('Using Group Contingencies');
            }
            if (variableValues.p_behave_expect_strat5 === '1') {
                strategies.push('Using Precorrection');
            }
            return strategies;
        },
        component: GoalItem,
        timestamp: 'behavioral_expectations_goal',
    },
    {
        id: 13,
        handle: 'ActiveSupervision',
        name: 'Active Supervision',
        variables: [
            'p_active_supervision_strat',
            'p_active_supervision_action1',
            'p_active_supervision_action2',
            'p_active_supervision_action3',
            'p_active_supervision_action4',
            'p_active_supervision_monitor1',
            'p_active_supervision_monitor2',
            'p_active_supervision_monitor3',
            'p_active_supervision_monitor4',
            'p_active_supervision_eval1',
            'p_active_supervision_eval2',
            'p_active_supervision_eval3',
            'p_active_supervision_eval4',
        ],
        props: { color: '#3B6028', section: 'Behavior Management' },
        condition: variableValues => {
            return variableValues.p_active_supervision_strat === '1';
        },
        strategy: () => {
            return ['Using Active Supervision'];
        },
        component: GoalItem,
        timestamp: 'active_supervision_goal',
    },
    {
        id: 14,
        handle: 'UsePraise',
        name: 'Use of Praise',
        variables: [
            'p_use_praise_strat1',
            'p_use_praise_strat2',
            'p_use_praise_strat3',
            'p_use_praise_strat4',
            'p_use_praise_strat5',
            'p_use_praise_strat0',
            'p_use_praise_action1',
            'p_use_praise_action2',
            'p_use_praise_action3',
            'p_use_praise_action4',
            'p_use_praise_monitor1',
            'p_use_praise_monitor2',
            'p_use_praise_monitor3',
            'p_use_praise_monitor4',
            'p_use_praise_eval1',
            'p_use_praise_eval2',
            'p_use_praise_eval3',
            'p_use_praise_eval4',
        ],
        props: { color: '#3B6028', section: 'Behavior Management' },
        condition: variableValues => {
            return (
                variableValues.p_use_praise_strat1 === '1' ||
                variableValues.p_use_praise_strat2 === '1' ||
                variableValues.p_use_praise_strat3 === '1' ||
                variableValues.p_use_praise_strat4 === '1' ||
                variableValues.p_use_praise_strat5 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_use_praise_strat1 === '1') {
                strategies.push('Using Active Supervision');
            }
            if (variableValues.p_use_praise_strat2 === '1') {
                strategies.push('Using Behavior-specific Praise');
            }
            if (variableValues.p_use_praise_strat3 === '1') {
                strategies.push('Using Group Contingencies');
            }
            if (variableValues.p_use_praise_strat4 === '1') {
                strategies.push('Using Planned Ignoring');
            }
            if (variableValues.p_use_praise_strat5 === '1') {
                strategies.push('Using Social and Emotional Coaching');
            }
            return strategies;
        },
        component: GoalItem,
        timestamp: 'praise_goal',
    },
    {
        id: 15,
        handle: 'UseReprimands',
        name: 'Use of Reprimands',
        variables: [
            'p_use_reprimands_strat1',
            'p_use_reprimands_strat2',
            'p_use_reprimands_strat3',
            'p_use_reprimands_strat0',
            'p_use_reprimands_action1',
            'p_use_reprimands_action2',
            'p_use_reprimands_action3',
            'p_use_reprimands_action4',
            'p_use_reprimands_monitor1',
            'p_use_reprimands_monitor2',
            'p_use_reprimands_monitor3',
            'p_use_reprimands_monitor4',
            'p_use_reprimands_eval1',
            'p_use_reprimands_eval2',
            'p_use_reprimands_eval3',
            'p_use_reprimands_eval4',
        ],
        props: { color: '#3B6028', section: 'Behavior Management' },
        condition: variableValues => {
            return (
                variableValues.p_use_reprimands_strat1 === '1' ||
                variableValues.p_use_reprimands_strat2 === '1' ||
                variableValues.p_use_reprimands_strat3 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_use_reprimands_strat1 === '1') {
                strategies.push('Teaching Behavior Expectations');
            }
            if (variableValues.p_use_reprimands_strat2 === '1') {
                strategies.push('Using Group Contingencies');
            }
            if (variableValues.p_use_reprimands_strat3 === '1') {
                strategies.push('Using Precorrection');
            }

            return strategies;
        },
        component: GoalItem,
        timestamp: 'reprimands_goal',
    },
    {
        id: 16,
        handle: 'PosToNegRatio',
        name: 'Positive to Negative Ratio',
        variables: [
            'p_pos_neg_ratio_strat1',
            'p_pos_neg_ratio_strat2',
            'p_pos_neg_ratio_strat3',
            'p_pos_neg_ratio_strat4',
            'p_pos_neg_ratio_strat5',
            'p_pos_neg_ratio_strat6',
            'p_pos_neg_ratio_strat7',
            'p_pos_neg_ratio_strat0',
            'p_pos_neg_ratio_action1',
            'p_pos_neg_ratio_action2',
            'p_pos_neg_ratio_action3',
            'p_pos_neg_ratio_action4',
            'p_pos_neg_ratio_monitor1',
            'p_pos_neg_ratio_monitor2',
            'p_pos_neg_ratio_monitor3',
            'p_pos_neg_ratio_monitor4',
            'p_pos_neg_ratio_eval1',
            'p_pos_neg_ratio_eval2',
            'p_pos_neg_ratio_eval3',
            'p_pos_neg_ratio_eval4',
        ],
        props: { color: '#3B6028', section: 'Behavior Management' },
        condition: variableValues => {
            return (
                variableValues.p_pos_neg_ratio_strat1 === '1' ||
                variableValues.p_pos_neg_ratio_strat2 === '1' ||
                variableValues.p_pos_neg_ratio_strat3 === '1' ||
                variableValues.p_pos_neg_ratio_strat4 === '1' ||
                variableValues.p_pos_neg_ratio_strat5 === '1' ||
                variableValues.p_pos_neg_ratio_strat6 === '1' ||
                variableValues.p_pos_neg_ratio_strat7 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_pos_neg_ratio_strat1 === '1') {
                strategies.push('Teaching Behavior Expectations');
            }
            if (variableValues.p_pos_neg_ratio_strat2 === '1') {
                strategies.push('Using Active Supervision');
            }
            if (variableValues.p_pos_neg_ratio_strat3 === '1') {
                strategies.push('Using Behavior-specific Praise');
            }
            if (variableValues.p_pos_neg_ratio_strat4 === '1') {
                strategies.push('Using Group Contingencies');
            }
            if (variableValues.p_pos_neg_ratio_strat5 === '1') {
                strategies.push('Using Planned Ignoring');
            }
            if (variableValues.p_pos_neg_ratio_strat6 === '1') {
                strategies.push('Using Precorrection');
            }
            if (variableValues.p_pos_neg_ratio_strat7 === '1') {
                strategies.push('Using Social and Emotional Coaching');
            }
            return strategies;
        },
        component: GoalItem,
        timestamp: 'ratio_goal',
    },
    {
        id: 17,
        handle: 'UseReinforce',
        name: 'Use of Reinforcement',
        variables: [
            'p_use_reinforce_strat1',
            'p_use_reinforce_strat2',
            'p_use_reinforce_strat0',
            'p_use_reinforce_action1',
            'p_use_reinforce_action2',
            'p_use_reinforce_action3',
            'p_use_reinforce_action4',
            'p_use_reinforce_monitor1',
            'p_use_reinforce_monitor2',
            'p_use_reinforce_monitor3',
            'p_use_reinforce_monitor4',
            'p_use_reinforce_eval1',
            'p_use_reinforce_eval2',
            'p_use_reinforce_eval3',
            'p_use_reinforce_eval4',
        ],
        props: { color: '#3B6028', section: 'Behavior Management' },
        condition: variableValues => {
            return (
                variableValues.p_use_reinforce_strat1 === '1' ||
                variableValues.p_use_reinforce_strat2 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_use_reinforce_strat1 === '1') {
                strategies.push('Identifying Reinforcers for the Classroom');
            }
            if (variableValues.p_use_reinforce_strat2 === '1') {
                strategies.push('Using Group Contingencies');
            }

            return strategies;
        },
        component: GoalItem,
        timestamp: 'reinforcement_goal',
    },
    {
        id: 18,
        handle: 'OtherBehaviorMgt',
        name: 'Other Behavior Management',
        variables: [
            'p_other_behave_mgmt_strat1',
            'p_other_behave_mgmt_strat2',
            'p_other_behave_mgmt_strat2a',
            'p_other_behave_mgmt_strat2b',
            'p_other_behave_mgmt_strat2c',
            'p_other_behave_mgmt_strat2d',
            'p_other_behave_mgmt_strat2e',
            'p_other_behave_mgmt_strat2f',
            'p_other_behave_mgmt_strat2g',
            'p_other_behave_mgmt_strat2h',
            'p_other_behave_mgmt_strat2i',
            'p_other_behave_mgmt_strat2j',
            'p_other_behave_mgmt_action1',
            'p_other_behave_mgmt_action2',
            'p_other_behave_mgmt_action3',
            'p_other_behave_mgmt_action4',
            'p_other_behave_mgmt_monitor1',
            'p_other_behave_mgmt_monitor2',
            'p_other_behave_mgmt_monitor3',
            'p_other_behave_mgmt_monitor4',
            'p_other_behave_mgmt_eval1',
            'p_other_behave_mgmt_eval2',
            'p_other_behave_mgmt_eval3',
            'p_other_behave_mgmt_eval4',
        ],
        props: { color: '#3B6028', section: 'Behavior Management' },
        condition: variableValues => {
            return (
                variableValues.p_other_behave_mgmt_strat1 &&
                variableValues.p_other_behave_mgmt_strat1 !== '0'
            );
        },
        strategy: variableValues => {
            let options;
            let firstStrat;
            let secondStratVar;
            let secondStrat;

            switch (variableValues.p_other_behave_mgmt_strat1) {
                case '1':
                    firstStrat = 'Defining and Teaching Classroom Rules';
                    options = {
                        '1': 'Identifying Reinforcers for the Classroom',
                        '2': 'Teaching Behavior Expectations',
                        '3': 'Teaching Classroom Routines',
                        '4': 'Using Active Supervision',
                        '5': 'Using Behavior-specific Praise',
                        '6': 'Using Group Contingencies',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_behave_mgmt_strat2a';

                    break;
                case '2':
                    firstStrat = 'Identifying Reinforcers for the Classroom';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Teaching Behavior Expectations',
                        '3': 'Teaching Classroom Routines',
                        '4': 'Using Active Supervision',
                        '5': 'Using Behavior-specific Praise',
                        '6': 'Using Group Contingencies',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_behave_mgmt_strat2b';
                    break;
                case '3':
                    firstStrat = 'Teaching Behavior Expectations';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Identifying Reinforcers for the Classroom',
                        '3': 'Teaching Classroom Routines',
                        '4': 'Using Active Supervision',
                        '5': 'Using Behavior-specific Praise',
                        '6': 'Using Group Contingencies',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_behave_mgmt_strat2c';
                    break;
                case '4':
                    firstStrat = 'Teaching Classroom Routines';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Identifying Reinforcers for the Classroom',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Using Active Supervision',
                        '5': 'Using Behavior-specific Praise',
                        '6': 'Using Group Contingencies',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_behave_mgmt_strat2d';
                    break;
                case '5':
                    firstStrat = 'Using Active Supervision';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Identifying Reinforcers for the Classroom',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Teaching Classroom Routines',
                        '5': 'Using Behavior-specific Praise',
                        '6': 'Using Group Contingencies',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_behave_mgmt_strat2e';
                    break;
                case '6':
                    firstStrat = 'Using Behavior-specific Praise';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Identifying Reinforcers for the Classroom',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Teaching Classroom Routines',
                        '5': 'Using Active Supervision',
                        '6': 'Using Group Contingencies',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_behave_mgmt_strat2f';
                    break;
                case '7':
                    firstStrat = 'Using Group Contingencies';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Identifying Reinforcers for the Classroom',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Teaching Classroom Routines',
                        '5': 'Using Active Supervision',
                        '6': 'Using Behavior-specific Praise',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_behave_mgmt_strat2g';
                    break;
                case '8':
                    firstStrat = 'Using Planned Ignoring';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Identifying Reinforcers for the Classroom',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Teaching Classroom Routines',
                        '5': 'Using Active Supervision',
                        '6': 'Using Behavior-specific Praise',
                        '7': 'Using Group Contingencies',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_behave_mgmt_strat2h';
                    break;
                case '9':
                    firstStrat = 'Using Precorrection';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Identifying Reinforcers for the Classroom',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Teaching Classroom Routines',
                        '5': 'Using Active Supervision',
                        '6': 'Using Behavior-specific Praise',
                        '7': 'Using Group Contingencies',
                        '8': 'Using Planned Ignoring',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_behave_mgmt_strat2i';
                    break;
                case '10':
                    firstStrat = 'Using Social and Emotional Coaching';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Identifying Reinforcers for the Classroom',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Teaching Classroom Routines',
                        '5': 'Using Active Supervision',
                        '6': 'Using Behavior-specific Praise',
                        '7': 'Using Group Contingencies',
                        '8': 'Using Planned Ignoring',
                        '9': 'Using Precorrection',
                    };
                    secondStratVar = 'p_other_behave_mgmt_strat2i';
                    break;

                default:
                    break;
            }

            if (firstStrat) {
                if (variableValues.OtherBehaviorMgt_Strategy2 === '1') {
                    secondStrat = options[variableValues[secondStratVar]];
                }
                if (secondStrat) {
                    return [firstStrat, secondStrat];
                }
                return [firstStrat];
            }
            return [];
        },
        component: GoalItem,
        timestamp: 'behavior_management_other_goal',
    },
    {
        id: 19,
        handle: 'UseNoncontingentAtn',
        name: 'Use of Noncontingent Attention',
        variables: [
            'p_noncontingent_atn_strat1',
            'p_noncontingent_atn_strat2',
            'p_noncontingent_atn_strat0',
            'p_noncontingent_atn_action1',
            'p_noncontingent_atn_action2',
            'p_noncontingent_atn_action3',
            'p_noncontingent_atn_action4',
            'p_noncontingent_atn_monitor1',
            'p_noncontingent_atn_monitor2',
            'p_noncontingent_atn_monitor3',
            'p_noncontingent_atn_monitor4',
            'p_noncontingent_atn_eval1',
            'p_noncontingent_atn_eval2',
            'p_noncontingent_atn_eval3',
            'p_noncontingent_atn_eval4',
        ],
        props: { color: '#941D21', section: 'Classroom Climate' },
        condition: variableValues => {
            return (
                variableValues.p_noncontingent_atn_strat1 === '1' ||
                variableValues.p_noncontingent_atn_strat2 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_noncontingent_atn_strat1 === '1') {
                strategies.push('Greeting Students at the Door');
            }
            if (variableValues.p_noncontingent_atn_strat2 === '1') {
                strategies.push('Using Journals to Build Relationships');
            }

            return strategies;
        },
        component: GoalItem,
        timestamp: 'noncontingent_attention_goal',
    },
    {
        id: 20,
        handle: 'InteractStudents',
        name: 'Interactions with Students',
        variables: [
            'p_interact_studs_strat1',
            'p_interact_studs_strat2',
            'p_interact_studs_strat3',
            'p_interact_studs_strat4',
            'p_interact_studs_strat5',
            'p_interact_studs_strat6',
            'p_interact_studs_strat0',
            'p_interact_studs_action1',
            'p_interact_studs_action2',
            'p_interact_studs_action3',
            'p_interact_studs_action4',
            'p_interact_studs_monitor1',
            'p_interact_studs_monitor2',
            'p_interact_studs_monitor3',
            'p_interact_studs_monitor4',
            'p_interact_studs_eval1',
            'p_interact_studs_eval2',
            'p_interact_studs_eval3',
            'p_interact_studs_eval4',
        ],
        props: { color: '#941D21', section: 'Classroom Climate' },
        condition: variableValues => {
            return (
                variableValues.p_interact_studs_strat1 === '1' ||
                variableValues.p_interact_studs_strat2 === '1' ||
                variableValues.p_interact_studs_strat3 === '1' ||
                variableValues.p_interact_studs_strat4 === '1' ||
                variableValues.p_interact_studs_strat5 === '1' ||
                variableValues.p_interact_studs_strat6 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_interact_studs_strat1 === '1') {
                strategies.push('Greeting Students at the Door');
            }
            if (variableValues.p_interact_studs_strat2 === '1') {
                strategies.push('Teaching Behavior Expectations');
            }
            if (variableValues.p_interact_studs_strat3 === '1') {
                strategies.push('Using Active Supervision');
            }
            if (variableValues.p_interact_studs_strat4 === '1') {
                strategies.push('Using Behavior-specific Praise');
            }
            if (variableValues.p_interact_studs_strat5 === '1') {
                strategies.push('Using Journals to Build Relationships');
            }
            if (variableValues.p_interact_studs_strat6 === '1') {
                strategies.push('Using Social and Emotional Coaching');
            }

            return strategies;
        },
        component: GoalItem,
        timestamp: 'interactions_goal',
    },
    {
        id: 21,
        handle: 'DisruptiveBehavior',
        name: 'Level of Disruptive Behavior ',
        variables: [
            'p_disruptive_behave_strat1',
            'p_disruptive_behave_strat2',
            'p_disruptive_behave_strat3',
            'p_disruptive_behave_strat4',
            'p_disruptive_behave_strat5',
            'p_disruptive_behave_strat6',
            'p_disruptive_behave_strat7',
            'p_disruptive_behave_strat0',
            'p_disruptive_behave_action1',
            'p_disruptive_behave_action2',
            'p_disruptive_behave_action3',
            'p_disruptive_behave_action4',
            'p_disruptive_behave_monitor1',
            'p_disruptive_behave_monitor2',
            'p_disruptive_behave_monitor3',
            'p_disruptive_behave_monitor4',
            'p_disruptive_behave_eval1',
            'p_disruptive_behave_eval2',
            'p_disruptive_behave_eval3',
            'p_disruptive_behave_eval4',
        ],
        props: { color: '#941D21', section: 'Classroom Climate' },
        condition: variableValues => {
            return (
                variableValues.p_disruptive_behave_strat1 === '1' ||
                variableValues.p_disruptive_behave_strat2 === '1' ||
                variableValues.p_disruptive_behave_strat3 === '1' ||
                variableValues.p_disruptive_behave_strat4 === '1' ||
                variableValues.p_disruptive_behave_strat5 === '1' ||
                variableValues.p_disruptive_behave_strat6 === '1' ||
                variableValues.p_disruptive_behave_strat7 === '1'
            );
        },
        strategy: variableValues => {
            let strategies = [];
            if (variableValues.p_disruptive_behave_strat1 === '1') {
                strategies.push('Defining and Teaching Classroom Rules');
            }
            if (variableValues.p_disruptive_behave_strat2 === '1') {
                strategies.push('Teaching Behavior Expectations');
            }
            if (variableValues.p_disruptive_behave_strat3 === '1') {
                strategies.push('Using Active Supervision');
            }
            if (variableValues.p_disruptive_behave_strat4 === '1') {
                strategies.push('Using Behavior-specific Praise');
            }
            if (variableValues.p_disruptive_behave_strat5 === '1') {
                strategies.push('Using Group Contingencies');
            }
            if (variableValues.p_disruptive_behave_strat6 === '1') {
                strategies.push('Using Planned Ignoring');
            }
            if (variableValues.p_disruptive_behave_strat7 === '1') {
                strategies.push('Using Precorrection');
            }

            return strategies;
        },
        component: GoalItem,
        timestamp: 'disruptive_behavior_goal',
    },
    {
        id: 22,
        handle: 'OtherClassClimate',
        name: 'Other Classroom Climate',
        variables: [
            'p_other_class_climate_strat1',
            'p_other_class_climate_strat2',
            'p_other_class_climate_strat2a',
            'p_other_class_climate_strat2b',
            'p_other_class_climate_strat2c',
            'p_other_class_climate_strat2d',
            'p_other_class_climate_strat2e',
            'p_other_class_climate_strat2f',
            'p_other_class_climate_strat2g',
            'p_other_class_climate_strat2h',
            'p_other_class_climate_strat2i',
            'p_other_class_climate_strat2j',
            'p_other_class_climate_action1',
            'p_other_class_climate_action2',
            'p_other_class_climate_action3',
            'p_other_class_climate_action4',
            'p_other_class_climate_monitor1',
            'p_other_class_climate_monitor2',
            'p_other_class_climate_monitor3',
            'p_other_class_climate_monitor4',
            'p_other_class_climate_eval1',
            'p_other_class_climate_eval2',
            'p_other_class_climate_eval3',
            'p_other_class_climate_eval4',
        ],
        props: { color: '#941D21', section: 'Classroom Climate' },
        condition: variableValues => {
            return (
                variableValues.p_other_class_climate_strat1 &&
                variableValues.p_other_class_climate_strat1 !== '0'
            );
        },
        strategy: variableValues => {
            let options;
            let firstStrat;
            let secondStratVar;
            let secondStrat;

            switch (variableValues.p_other_class_climate_strat1) {
                case '1':
                    firstStrat = 'Defining and Teaching Classroom Rules';
                    options = {
                        '1': 'Greeting Students at the Door',
                        '2': 'Teaching Behavior Expectations',
                        '3': 'Using Active Supervision',
                        '4': 'Using Behavior-specific Praise',
                        '5': 'Using Group Contingencies',
                        '6': 'Using Journals to Build Relationships',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_class_climate_strat2a';

                    break;
                case '2':
                    firstStrat = 'Greeting Students at the Door';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Teaching Behavior Expectations',
                        '3': 'Using Active Supervision',
                        '4': 'Using Behavior-specific Praise',
                        '5': 'Using Group Contingencies',
                        '6': 'Using Journals to Build Relationships',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_class_climate_strat2b';
                    break;
                case '3':
                    firstStrat = 'Teaching Behavior Expectations';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Greeting Students at the Door',
                        '3': 'Using Active Supervision',
                        '4': 'Using Behavior-specific Praise',
                        '5': 'Using Group Contingencies',
                        '6': 'Using Journals to Build Relationships',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_class_climate_strat2c';
                    break;
                case '4':
                    firstStrat = 'Using Active Supervision';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Greeting Students at the Door',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Using Behavior-specific Praise',
                        '5': 'Using Group Contingencies',
                        '6': 'Using Journals to Build Relationships',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_class_climate_strat2d';
                    break;
                case '5':
                    firstStrat = 'Using Behavior-specific Praise';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Greeting Students at the Door',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Using Active Supervision',
                        '5': 'Using Group Contingencies',
                        '6': 'Using Journals to Build Relationships',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_class_climate_strat2e';
                    break;
                case '6':
                    firstStrat = 'Using Group Contingencies';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Greeting Students at the Door',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Using Active Supervision',
                        '5': 'Using Behavior-specific Praise',
                        '6': 'Using Journals to Build Relationships',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_class_climate_strat2f';
                    break;
                case '7':
                    firstStrat = 'Using Journals to Build Relationships';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Greeting Students at the Door',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Using Active Supervision',
                        '5': 'Using Behavior-specific Praise',
                        '6': 'Using Group Contingencies',
                        '7': 'Using Planned Ignoring',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_class_climate_strat2g';
                    break;
                case '8':
                    firstStrat = 'Using Planned Ignoring';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Greeting Students at the Door',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Using Active Supervision',
                        '5': 'Using Behavior-specific Praise',
                        '6': 'Using Group Contingencies',
                        '7': 'Using Journals to Build Relationships',
                        '8': 'Using Precorrection',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_class_climate_strat2h';
                    break;
                case '9':
                    firstStrat = 'Using Precorrection';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Greeting Students at the Door',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Using Active Supervision',
                        '5': 'Using Behavior-specific Praise',
                        '6': 'Using Group Contingencies',
                        '7': 'Using Journals to Build Relationships',
                        '8': 'Using Planned Ignoring',
                        '9': 'Using Social and Emotional Coaching',
                    };
                    secondStratVar = 'p_other_class_climate_strat2i';
                    break;
                case '10':
                    firstStrat = 'Using Social and Emotional Coaching';
                    options = {
                        '1': 'Defining and Teaching Classroom Rules',
                        '2': 'Greeting Students at the Door',
                        '3': 'Teaching Behavior Expectations',
                        '4': 'Using Active Supervision',
                        '5': 'Using Behavior-specific Praise',
                        '6': 'Using Group Contingencies',
                        '7': 'Using Journals to Build Relationships',
                        '8': 'Using Planned Ignoring',
                        '9': 'Using Precorrection',
                    };
                    secondStratVar = 'p_other_class_climate_strat2j';
                    break;

                default:
                    break;
            }

            if (firstStrat) {
                if (variableValues.p_other_class_climate_strat2 === '1') {
                    secondStrat = options[variableValues[secondStratVar]];
                }
                if (secondStrat) {
                    return [firstStrat, secondStrat];
                }
                return [firstStrat];
            }
            return [];
        },
        component: GoalItem,
        timestamp: 'classroom_climate_other_goal',
    },
    {
        id: 23,
        handle: 'Considerations',
        name: 'When considering your goal(s):',
        variables: [
            'p_goals_importance1',
            'p_goals_importance2',
            'p_goals_importance3',
            'p_goals_importance4',
            'p_goals_importance5',
            'p_goals_importance6',
        ],
        condition: () => {
            return true;
        },
        component: Considerations,
    },
];

const InterventionPlanning = props => {
    const { selectedPerson } = props;
    const [report, setReport] = useState(null);
    const [data, setData] = useState(null);
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [completedBy, setCompletedBy] = useState(null);
    useEffect(() => {
        const allVariables = getGoals();

        async function getData() {
            const res = await fetchData(allVariables);

            const values = res ? res.data.roles.anyRole.planningMetrics.variables : null;
            const stat = res ? res.data.roles.anyRole.planningProgress[0] : null;
            const completer = res ? res.data.roles.anyRole.targetEvent.pawn.user.fullName : null;
            if (stat && stat.completed) {
                setData(values);
                setCompletedBy(completer);
            }

            setStatus(stat);
            setLoading(false);
        }

        getData();
    }, [data]);

    const fetchData = async allVariables => {
        let results = null;
        if (selectedPerson.deploymentId) {
            results = await apollo.query({
                query: gql`
                    ${variableQuery}
                `,
                fetchPolicy: 'network-only',
                variables: {
                    pawnId: selectedPerson.value,
                    deploymentId: selectedPerson.deploymentId,
                    assignmentId: selectedPerson.assignmentId,
                    variableList: allVariables,
                },
            });
        }

        return results;
    };

    const filteredGoals = data ? goals.filter(goal => goal.condition(data)) : null;

    const download = () => {
        openAll();
        setTimeout(async () => {
            Notification({
                message: 'Please wait a moment while your PDF is prepared',
                type: 'info',
                duration: 5000,
            });
            await report.download();
            closeAll();
        }, 1000);
    };

    const getGoals = () => {
        const allVariables = [];

        goals.forEach(goal => {
            for (const variable of goal.variables) {
                allVariables.push(variable);
            }
        });
        return allVariables;
    };

    const reloadData = async () => {
        const allVariables = getGoals();
        const res = await fetchData(allVariables);
        const values = res ? res.data.roles.anyRole.planningMetrics.variables : null;
        const stat = res ? res.data.roles.anyRole.planningProgress[0] : null;
        setStatus(stat);
        setData(values);
    };

    const onComplete = async (pawnId, deploymentId) => {
        if (!status || (status && !status.completed)) {
            addEvent(
                {
                    eventType: 'planningSurveyCompleted',
                    targetId: +pawnId,
                    targetType: 'pawn',
                    metadata: JSON.stringify({ deploymentId }),
                },
                { saveImmediately: true }
            );
        }

        await reloadData();
    };

    const onNewClose = async () => {
        setLoading(true);
        await reloadData();
        await props.loadData();
        setLoading(false);
    };

    const openAll = () => {
        setShowAll(true);
    };
    const closeAll = () => {
        if (showAll === true) {
            setShowAll(false);
        } else if (showAll === false) {
            setShowAll(null);
        } else if (showAll === null) {
            setShowAll(false);
        }
    };
    const completed = status && status.completed;

    return (
        <Loader loading={loading} removeChildren>
            {data && completed && (
                <>
                    <div className={style.topBar}>
                        {status && (
                            <div>
                                <strong>Planning Form Completed: </strong>
                                {format(status.completedDate, 'MM/DD/YYYY')}
                            </div>
                        )}
                        <div>
                            <button
                                type="button"
                                className={`btn btn-primary ${style.openButton}`}
                                onClick={openAll}
                            >
                                Open All <ArrowClosed />
                            </button>
                            <button
                                type="button"
                                className={`btn btn-primary ${style.closeButton}`}
                                onClick={closeAll}
                            >
                                Close All <ArrowOpen />
                            </button>
                            <button type="button" className="btn btn-primary" onClick={download}>
                                Download PDF
                            </button>
                        </div>
                    </div>
                    <div className={style.pageWrapper}>
                        <Report
                            width="100%"
                            showPagination={false}
                            onRef={element => setReport(element)}
                            downloadFilename={`PlanningFormReport_${
                                selectedPerson ? selectedPerson.label : ''
                            }_${format(status.completedDate, 'MM/DD/YYYY')}`}
                        >
                            {filteredGoals.map((goal, i) => {
                                const goalDate = format(
                                    props.timestamps[goal.timestamp],
                                    'MM/DD/YYYY'
                                );
                                return (
                                    <Page>
                                        <PrintOnly>
                                            {i === 0 && (
                                                <>
                                                    <h3
                                                        style={{
                                                            fontFamily: "'Pridi', sans-serif",
                                                        }}
                                                    >
                                                        CCU Planning Form
                                                    </h3>
                                                    <div
                                                        style={{
                                                            marginBottom: '1em',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <div>
                                                            {' '}
                                                            <strong>
                                                                Planning Form Completed:{' '}
                                                            </strong>
                                                            {format(
                                                                status.completedDate,
                                                                'MM/DD/YYYY'
                                                            )}
                                                        </div>
                                                        <div
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            Page {i + 1} of {filteredGoals.length}{' '}
                                                        </div>
                                                    </div>{' '}
                                                </>
                                            )}
                                        </PrintOnly>
                                        <PrintOnly>
                                            {i !== 0 && (
                                                <div
                                                    style={{
                                                        textAlign: 'right',
                                                        marginBottom: '1em',
                                                    }}
                                                >
                                                    Page {i + 1} of {filteredGoals.length}{' '}
                                                </div>
                                            )}
                                        </PrintOnly>
                                        <div key={goal.id}>
                                            <goal.component
                                                label={goal.name}
                                                timestamp={
                                                    goalDate !== 'Invalid Date' ? goalDate : null
                                                }
                                                show={showAll}
                                                index={i}
                                                variables={goal.variables}
                                                {...goal.props}
                                                data={data}
                                                strategy={goal.strategy ? goal.strategy : null}
                                            />
                                        </div>
                                        {i === filteredGoals.length - 1 && (
                                            <div style={{ marginTop: '1em' }}>
                                                <div>
                                                    <strong>Completed By:</strong>{' '}
                                                    {completedBy && completedBy}
                                                </div>
                                            </div>
                                        )}
                                    </Page>
                                );
                            })}
                        </Report>
                    </div>{' '}
                </>
            )}
            <PlanningSurvey
                {...props}
                status={status}
                onComplete={onComplete}
                onNewClose={onNewClose}
            />
        </Loader>
    );
};

InterventionPlanning.propTypes = {
    selectedPerson: PropTypes.object,
    goals: PropTypes.object,
    onComplete: PropTypes.func,
    loadData: PropTypes.func,
    noDevTags: PropTypes.bool,
    timestamps: PropTypes.object,
};

export default InterventionPlanning;
