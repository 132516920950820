import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import format from 'date-fns/format';
const Boilerplate = window.cccisd.boilerplate;

const Goals = props => {
    const { goals, rows, timestamps, devTags, noDevTags, user } = props;
    const getSectionColor = section => {
        const colors = {
            'Classroom Structure': '#8b4195',
            'Instruction Management': '#d78600',
            'Behavior Management': '#3c6028',
            'Classroom Climate': '#931c22',
        };

        return colors[section] || 'black';
    };
    let goalsItems;

    if (noDevTags && user) {
        if (user.completedDate && !user.expired && user.role === 'teacher') {
            return (
                <div className={style.goalBox}>
                    <div className={style.noGoals}>
                        Your coach has either not completed or not shared the Check-Up Feedback Form
                        yet.
                    </div>
                </div>
            );
        }
        return (
            <div className={style.goalBox}>
                <div className={style.noGoals}>
                    The Check-Up Feedback Form has not been completed yet. Click the Start New
                    Survey button below to enter feedback. The Check-Up Feedback Form will display
                    with the results once this survey has been submitted.
                </div>
            </div>
        );
    }
    if (goals) {
        const scoreArr = Object.keys(goals)
            .map(key => {
                return { tag: key, value: goals[key] };
            })
            .filter(thing => thing.value === true);

        const finalArr = scoreArr.map(item => {
            const index = rows.findIndex(thing => thing.goalTag === item.tag);
            const match = rows[index];
            const labelTag = match.labelTag ? match.labelTag : '';
            const section = props.determineSection(index);

            return {
                label: match.label,
                value: item.value,
                section,
                timestamp: timestamps[match.goalMetric],
                labelTag: match.labelTag ? labelTag : '',
            };
        });

        if (scoreArr.length === 0) {
            return (
                <div className={style.goalBox}>
                    <div className={style.noGoals}>
                        No goals have been selected yet. Please select goals from the Goal column of
                        the Check-Up Feedback report below and click the Submit Goals button to save
                        them.
                    </div>
                </div>
            );
        }

        goalsItems = finalArr.map((item, i) => {
            const timeArr = item.timestamp ? item.timestamp.split(' ') : '';
            const color = getSectionColor(item.section);
            const displayDate = timeArr[0] ? format(timeArr[0], 'MM/DD/YYYY') : '';
            const label = item.labelTag ? (
                <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                    Other:{' '}
                    <span style={{ marginLeft: '.25em', textDecoration: 'underline' }}>
                        {devTags[item.labelTag]}
                    </span>
                </div>
            ) : (
                item.label
            );
            return (
                <div className={style.goal} style={{ borderLeftColor: color }} key={i}>
                    <div className={style.titleButton}>
                        <span
                            style={{
                                whiteSpace: 'nowrap',
                                fontWeight: 'bold',
                            }}
                        >
                            {label}
                        </span>
                        <span style={{ fontWeight: 'bold' }}> | </span>
                        <span style={{ fontStyle: 'italic' }}>{item.section}</span>
                    </div>
                    <div className={style.goalDate}>
                        <button
                            type="button"
                            className={`btn btn-default ${style.stratButton}`}
                            onClick={() => {
                                const tags = {
                                    'Pacing of Instruction': 'Pacing',
                                    'Use of Reinforcement': 'Used Variety of Reinforcement',
                                    'Academic Objectives': 'Academic Objectives Clear',
                                    'Behavioral Expectations': 'Behavioral Expectations Clear',
                                };

                                if (item.label === 'Other:') {
                                    window.location = Boilerplate.url(`/checkUpMenu`);
                                } else {
                                    window.location = Boilerplate.url(
                                        `/checkUpMenu?tab=Check-Up Menu&tag=${tags[item.label] ||
                                            item.label}`
                                    );
                                }
                            }}
                        >
                            View Strategies
                        </button>
                        <span>
                            <span>Created:</span>{' '}
                            <span style={{ fontWeight: 'normal' }}>{displayDate}</span>
                        </span>
                    </div>
                </div>
            );
        });
    }

    return (
        <>
            {goalsItems && (
                <div className={style.goalBox}>
                    <div className={style.goalBoxTitle}>
                        <h3>Current Goals:</h3>
                        <span className={style.userName}>{props.user.label}</span>
                    </div>
                    <div className={style.goalsSpot}>{goalsItems}</div>
                </div>
            )}
        </>
    );
};

Goals.propTypes = {
    determineSection: PropTypes.func,
    user: PropTypes.object,
    goals: PropTypes.object,
    devTags: PropTypes.object,
    noDevTags: PropTypes.bool,
    timestamps: PropTypes.object,
    rows: PropTypes.array,
};

export default Goals;
