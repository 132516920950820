import React from 'react';
import Modal from 'cccisd-modal';
import PropTypes from 'prop-types';
import Confirm from 'cccisd-confirm';
import Notification from 'cccisd-notification';
import UnlinkIcon from 'cccisd-icons/unlink4';
import Info from 'cccisd-icons/info2';
import Note from 'cccisd-icons/notification2';
import Link from 'cccisd-icons/link';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';
import Axios from 'cccisd-axios';
import { addEvent } from 'cccisd-laravel-eventlog';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const ViewLinks = props => {
    const modal = React.createRef();
    const { parentPawns, pendingRequests, user, reloadUserData } = props;
    const isCoach = Fortress.user.acting.role.handle === 'coach';

    const confirmUnlink = parent => {
        Confirm({
            message: `Confirm Unlink`,
            description: `Are you sure you want to unlink from this ${parent.pawn.role}? If you unlink, you will no longer be able to see their CCU data within the application. If you wish to unlink, select “continue”. If you want to relink with this ${parent.pawn.role} later, contact them via e-mail`,
            confirmLabel: 'Continue',
            abortLabel: 'Cancel',
        }).then(() => {
            onUnlink(parent);
        });
    };
    const onUnlink = async parent => {
        const parentCoach = parent.pawn.role === 'coach';
        const parentArr = parentPawns.map(item => item.pawn.pawnId);
        const filtered = parentArr.filter(item => item !== parent.pawn.pawnId);

        const data = {
            username: user.username,
            first_name: user.firstName,
            last_name: user.lastName,
            parentPawns: filtered.length > 0 ? JSON.stringify(filtered) : null,
            pawnId: user.pawnId,
            pawnHash: user.pawnHash,
        };
        const response = await Axios.put(
            Boilerplate.route('api.nexus.pawn.update', { pawn: user.pawnId }),
            data
        );
        if (response.data && response.data.status === 'success') {
            const metadata = parentCoach
                ? {
                      coach: {
                          name: parent.user.fullName,
                          pawnId: parent.pawn.pawnId,
                      },
                      teacher: {
                          name: user.firstName + ' ' + user.lastName,
                          pawnId: user.pawnId,
                      },
                  }
                : {
                      admin: {
                          name: parent.user.fullName,
                          pawnId: parent.pawn.pawnId,
                      },
                      teacher: {
                          name: user.firstName + ' ' + user.lastName,
                          pawnId: user.pawnId,
                      },
                  };
            addEvent(
                {
                    eventType: parentCoach ? 'unlinkCoach' : 'unlinkAdmin',
                    targetId: user.pawnId,
                    targetType: 'pawn',
                    metadata: JSON.stringify(metadata),
                },
                { saveImmediately: true }
            );
            Notification({
                message: `The link with ${parent.pawn.role} ${parent.user.fullName} has been removed.`,
                type: 'success',
                duration: 5000,
            });
            reloadUserData(user.pawnId);
        }
    };

    const renderUnlink = parent => (
        <button
            type="button"
            className={`btn btn-default ${style.unlinkButton}`}
            onClick={() => {
                confirmUnlink(parent);
            }}
        >
            <UnlinkIcon />
        </button>
    );

    const renderAcceptButton = request => {
        const requestorHash = request.requestor.pawn.pawnHash;
        const requestorRole = request.requestor.pawn.role;
        const requesteeHash = request.requesteePawn.pawn.pawnHash;
        const acceptanceURL = Boilerplate.url(`/acceptance/${requesteeHash}/${requestorHash}`);
        return (
            <button
                type="button"
                className={`btn btn-default ${style.buttonStyle}`}
                onClick={() => {
                    Confirm({
                        message: `Confirm Acceptance`,
                        description:
                            requestorRole === 'coach'
                                ? `Are you sure you wish to accept coaching? Accepting this invitation will break any other links you may have with a different coach.`
                                : `Are you sure you wish to accept administration?`,
                        confirmLabel: 'Continue',
                        abortLabel: 'Cancel',
                    }).then(() => {
                        window.location = acceptanceURL;
                    });
                }}
            >
                Accept
            </button>
        );
    };

    if (parentPawns.length + pendingRequests.length <= 2) {
        // Two Lines no modal

        return (
            <div className={style.onlyOne}>
                {parentPawns.length > 0 &&
                    parentPawns.map(parentPawn => (
                        <div style={{ textAlign: 'right' }}>
                            <strong>{parentPawn.pawn.roleName}:</strong> {parentPawn.user.fullName}{' '}
                            <span className={style.unlinkSpan}>
                                <Tooltip
                                    title={
                                        parentPawn.pawn.role === 'coach'
                                            ? '<p>Unlink with your coach.</p>'
                                            : '<p>Unlink with this admin.</p>'
                                    }
                                    size="medium"
                                    placement="bottom"
                                >
                                    {renderUnlink(parentPawn)}
                                </Tooltip>
                            </span>
                        </div>
                    ))}
                <br />
                {pendingRequests.length > 0 &&
                    pendingRequests.map(pendingRequest => (
                        <div style={{ textAlign: 'right' }}>
                            <Tooltip
                                title={
                                    pendingRequest.requestor.pawn.role === 'coach'
                                        ? '<p>You have received an invitation to be coached. If you accept coaching it will sever any existing links with a different coach</p>'
                                        : '<p>You have received an invitation to be administered. Click accept to accept this invitation.</p>'
                                }
                                size="medium"
                                placement="bottom"
                            >
                                <sup>
                                    <Info />
                                </sup>{' '}
                                <strong>
                                    Pending invitation from {pendingRequest.requestor.pawn.role}:
                                </strong>
                            </Tooltip>{' '}
                            {pendingRequest.requestor.user.fullName}
                            {'  '}
                            {renderAcceptButton(pendingRequest)}
                        </div>
                    ))}
            </div>
        );
    }
    if (parentPawns.length + pendingRequests.length > 2) {
        const title = isCoach
            ? '<p>View your admins and pending requests.</p>'
            : '<p>View your coaches, admins, and pending requests.</p>';
        const header = isCoach ? 'Admins' : 'Coaches and Admins';
        return (
            <div style={{ textAlign: 'right' }}>
                <Modal
                    trigger={
                        <button type="button" className={`btn ${style.buttonStyle}`}>
                            <Tooltip title={title} size="medium" placement="bottom">
                                <Link /> {isCoach ? 'View Admins' : 'View Coaches/Admins'}{' '}
                            </Tooltip>
                        </button>
                    }
                    size="large"
                    title={header}
                    ref={modal}
                    beforeClose={() => {
                        reloadUserData(user.pawnId);
                    }}
                >
                    <div className={style.tableWrapper}>
                        {parentPawns.length > 0 && (
                            <div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">User</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {parentPawns.map(parent => {
                                            return (
                                                <tr className="row">
                                                    <td className="col-md-6">
                                                        {parent.user.fullNameWithEmail}
                                                    </td>
                                                    <td className="col-md-4">
                                                        {parent.pawn.roleName}
                                                    </td>
                                                    <td className="col-md-2">
                                                        {renderUnlink(parent)}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {pendingRequests.length > 0 && (
                            <div>
                                <h4>Pending Requests</h4>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">User</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pendingRequests.map(request => {
                                            const requestor = request.requestor;

                                            return (
                                                <tr className="row">
                                                    <td className="col-md-6">
                                                        {requestor.user.fullNameWithEmail}
                                                    </td>
                                                    <td className="col-md-4">
                                                        {requestor.pawn.roleName}
                                                    </td>
                                                    <td className="col-md-2">
                                                        {renderAcceptButton(request)}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </Modal>{' '}
                {pendingRequests.length > 0 && (
                    <Tooltip
                        title={'<p>You have one or more pending invitations.</p>'}
                        size="medium"
                        placement="bottom"
                    >
                        <sup
                            className="text-danger"
                            style={{ backgroundColor: '#fff', fontSize: '14pt' }}
                        >
                            <Note />
                        </sup>
                    </Tooltip>
                )}
            </div>
        );
    }
    return null;
};
ViewLinks.propTypes = {
    user: PropTypes.object,
    parentPawns: PropTypes.array,
    pendingRequests: PropTypes.array,
    reloadUserData: PropTypes.func,
};
export default ViewLinks;
