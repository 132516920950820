import React, { useState, useEffect } from 'react';
import style from './style.css';
import ArrowClosed from 'cccisd-icons/arrow-right20';
import ArrowOpen from 'cccisd-icons/arrow-down20';

const GoalItem = props => {
    const { index, label, color, data, section, strategy, variables, show } = props;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (show) {
            setOpen(true);
        } else if (show === false) {
            setOpen(false);
        } else if (show === null) {
            setOpen(false);
        }
    }, [show]);

    const colors = {
        '#8B4195': { text: '#48224D', background: '#E8D9EA' },
        '#D78601': { text: '#804F00', background: '#F7E8D0' },
        '#3B6028': { text: '#2B461C', background: '#D8DFD4' },
        '#941D21': { text: '#72161B', background: '#E9D2D3' },
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const colorSet = colors[color];
    const strategies = strategy(data);

    const actionVars = variables.filter(item => item.includes('_action'));
    const monitorVars = variables.filter(item => item.includes('_monitor'));
    const evaluateVars = variables.filter(item => item.includes('_eval'));

    const actionQuestions = [
        'What needs to be done?',
        'How will this task get done?',
        'What is needed to get it done?',
        'When will it be completed?',
    ];
    const monitorQuestions = [
        'How will we monitor that the intervention is implemented?',
        'How will this task get done?',
        'What is needed to get it done?',
        'When will it be completed?',
    ];
    const evalQuestions = [
        'How will we evaluate if the intervention is working?',
        'How will this task get done?',
        'What is needed to get it done?',
        'When will it be completed?',
    ];

    const strats = {
        'Defining and Teaching Classroom Rules':
            'https://www.classroomcheckup.org/defining-and-teaching-classroom-rules/',

        'Developing and Using Clear Academic Objectives':
            'https://www.classroomcheckup.org/developing-and-using-clear-academic-objectives/',
        'Greeting Students at the Door':
            'https://www.classroomcheckup.org/greeting-students-at-the-door/',
        'Identifying Reinforcers for the Classroom':
            'https://www.classroomcheckup.org/identifying-reinforcers-for-the-classroom/',
        'Increasing Opportunities to Respond':
            'https://www.classroomcheckup.org/increasing-opportunities-to-respond/',
        'Physical Classroom Structure':
            'https://www.classroomcheckup.org/physical-classroom-structure/',
        'Posting and Using a Schedule':
            'https://www.classroomcheckup.org/posting-and-using-a-schedule/',
        'Providing Academic Feedback':
            'https://www.classroomcheckup.org/providing-academic-feedback/',
        'Teaching Behavior Expectations':
            'https://www.classroomcheckup.org/teaching-behavior-expectations/',
        'Teaching Classroom Routines':
            'https://www.classroomcheckup.org/teaching-classroom-routines/',
        'Using Active Supervision': 'https://www.classroomcheckup.org/using-active-supervision/',
        'Using Behavior-specific Praise':
            'https://www.classroomcheckup.org/using-behavior-specific-praise/',
        'Using Group Contingencies': 'https://www.classroomcheckup.org/using-group-contingencies/',
        'Using Journals to Build Relationships':
            'https://www.classroomcheckup.org/using-journals-to-build-relationships/',
        'Using Planned Ignoring': 'https://www.classroomcheckup.org/using-planned-ignoring/',
        'Using Precorrection': 'https://www.classroomcheckup.org/using-precorrection/',
        'Using Social and Emotional Coaching':
            'https://www.classroomcheckup.org/using-social-and-emotional-coaching/',
        'Using an Attention Signal': 'https://www.classroomcheckup.org/using-an-attention-signal/',
    };
    return (
        <>
            <div
                className={`${style.goalItem} ${open ? style.open : style.closed}`}
                style={{ backgroundColor: color, color: '#fff' }}
            >
                <div className={style.leftSide}>
                    <h4>
                        <strong>Goal {index + 1}:</strong> {label} |<i>{section}</i>
                    </h4>
                    <div className={style.strategyLabel}>
                        <strong
                            style={{
                                color: colorSet.background,
                                float: 'left',
                                marginRight: '1em',
                            }}
                        >
                            Strategy(ies) selected:
                        </strong>

                        {strategies.map(item => (
                            <a
                                className={style.stratLink}
                                href={strats[item]}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <div
                                    key={item}
                                    className={style.strategyTag}
                                    style={{
                                        backgroundColor: colorSet.background,
                                        color: colorSet.text,
                                    }}
                                >
                                    <span style={{ whiteSpace: 'nowrap' }}>{item}</span>
                                </div>{' '}
                            </a>
                        ))}
                    </div>
                </div>
                <div
                    className={style.rightSide}
                    onClick={() => {
                        handleClick();
                    }}
                >
                    <div className={style.date}>
                        <strong>Date Selected: </strong>
                        {props.timestamp}
                    </div>
                    {open ? <ArrowOpen /> : <ArrowClosed />}
                </div>
            </div>
            {open ? (
                <div className={style.detail}>
                    <div className={`${style.textRow} row`}>
                        <div className="col-md-12">
                            <strong>What actions will you take to meet this goal?</strong>
                        </div>
                    </div>
                    <div className={`${style.actionRowHeaders} ${style.bold} row`}>
                        <div className="col-md-6">TASK</div>
                        <div className="col-md-6">RESOURCES</div>
                    </div>
                    <div className={`${style.questionRow} row`}>
                        {actionVars.map((item, i) => (
                            <div key={item} className={`col-md-3 ${style.questionText}`}>
                                <span>{actionQuestions[i]}</span>
                            </div>
                        ))}
                    </div>
                    <div className={`${style.answerRow} row`}>
                        {actionVars.map((item, i) => (
                            <div className={`col-md-3  ${style.answerText}`} key={item}>
                                {data[item]}
                            </div>
                        ))}
                    </div>
                    <div className={`${style.textRow} row`}>
                        <div className="col-md-12">
                            <strong>How will we know if the intervention is working?</strong>
                        </div>
                    </div>
                    <div className={`${style.otherRowHeaders} ${style.bold} row`}>
                        <div className="col-md-6">TASK</div>
                        <div className="col-md-6">RESOURCES</div>
                    </div>
                    <div className={`${style.questionRow} row`}>
                        {monitorVars.map((item, i) => (
                            <div key={item} className={`col-md-3 ${style.questionText}`}>
                                <span>{monitorQuestions[i]}</span>
                            </div>
                        ))}
                    </div>
                    <div className={`${style.answerRow} row`}>
                        {monitorVars.map((item, i) => (
                            <div key={item} className={`col-md-3 ${style.answerText}`}>
                                {data[item]}
                            </div>
                        ))}
                    </div>
                    <div className={`${style.otherRowHeaders} ${style.bold} row`}>
                        <div className="col-md-6">TASK</div>
                        <div className="col-md-6">RESOURCES</div>
                    </div>
                    <div className={`${style.questionRow} row`}>
                        {evaluateVars.map((item, i) => (
                            <div key={item} className={`col-md-3 ${style.questionText}`}>
                                <span>{evalQuestions[i]}</span>
                            </div>
                        ))}
                    </div>
                    <div className={`${style.answerRow} row`}>
                        {evaluateVars.map((item, i) => (
                            <div key={item} className={`col-md-3 ${style.answerText}`}>
                                {data[item]}
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default GoalItem;
